.demo {
  padding: 100px 0;
}
.heading-title {
  margin-bottom: 100px;
}
.pricingTable {
  text-align: center;
  border: 1px solid #dbdbdb;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pricingTable .pricingTable-header .title {
  height: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.pricingTable .pricingTable-header .suntitle {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
}

.pricingTable .pricingTable-sign-up {
  margin-top: auto;
}
.pricingTable .pricingTable-header {
  padding: 0 5px;
}

.hourly .pricingTable-header {
  height: 280px;
}

.daily .pricingTable-header {
  height: 250px;
}

.yearly .pricingTable-header {
  height: 235px;
}

.pricingTable .pricingTable-header h3 {
  font-size: 20px !important;
  text-transform: uppercase;
}

.pricingTable .price-value {
  background: #fafafa;
  color: #666;
  font-weight: 900;
  padding: 15px 0;
  transition: all 0.3s ease 0s;
}

.pricingTable .price-value span {
  display: inline-block;
  font-size: 40px;
  font-weight: normal;
  line-height: normal;
}

.pricingTable .price-value small {
  font-size: 20px;
  position: relative;
  top: -15px;
  left: 0;
}

.pricingTable .price-value .subtitle {
  color: #666 !important;
  display: block;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}

.pricingTable .price-value-daily {
  background: #fafafa;
  color: #666;
  font-weight: 900;
  padding: 15px 10px;
  transition: all 0.3s ease 0s;
}

.pricingTable .price-value-daily span {
  color: #666;
  display: inline-block;
  font-size: 40px;
  font-weight: normal;
  line-height: normal;
}

.pricingTable .price-value-daily small {
  font-size: 20px;
  position: relative;
  top: -12px;
  left: 0;
}

.pricingTable .price-value-daily .subtitle {
  color: #666 !important;
  display: block;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}

.pricingTable .best-offer {
  background-color: #3498db;
  color: #fff;
  padding: 6px 50px;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  position: absolute;
  top: 20px;
  right: -55px;
  transform: rotate(45deg);
}

.pricingTable .pricingContent ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.pricingTable .pricingContent ul li {
  border-bottom: 1px solid #ededed;
  color: #9999a5;
  padding: 10px 0;
}

.pricingTable .pricingContent ul li:first-child {
  border-top: 1px solid #ededed;
}

.pricingTable .pricingTable-sign-up {
  padding: 25px 0;
}

.pricingTable .btn-block {
  background: #666;
  border-radius: 0px;
  border: 0px none;
  color: #fff;
  width: 50%;
  padding: 10px 5px;
  margin: 0 auto;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.pricingTable .btn-block:after {
  content: "\f090";
  font-family: "FontAwesome";
  padding-left: 10px;
  font-size: 15px;
}

.pricingTable .btn-block:hover {
  background: #282828;
  color: #fff;
}

@media screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 20px;
  }
}
.selecting_item {
  /* border: none; */
  width: 50%;
  padding: 10px 10px;
  background-color: white;
  border-radius: 10px;
}
.selecting_item:hover {
  outline: none;
}

#training-div .training-content {
  border: 1px solid #a9a9a9;
  background-color: #fff;
}
.training {
  /* border: 1px solid #0096FF; */
}

.training-tabs {
  background-color: #fff;
  padding: 10px 0;
}

.trainingTable {
  text-align: center;
  border-bottom: 1px solid #a9a9a9;
  position: relative;
  overflow: hidden;
}
.trainingTable:last-child {
  /* border-bottom: ; */
}

.trainingTablehead {
  text-align: center;
  /* border: 1px solid #dbdbdb; */
  position: relative;
  overflow: hidden;
}

.trainingTablehead .trainingTable-header h3 {
  color: #9999a5;
  font-size: 20px !important;
  text-transform: uppercase;
}

.trainingTable .price-value {
  background: #fafafa;
  color: #474747;
  /* font-weight: 900; */
  padding: 5px 0;
}

.trainingTable .price-value span {
  color: #666;
  display: inline-block;
  font-size: 40px;
  font-weight: normal;
  line-height: normal;
}

.trainingTable .price-value small {
  font-size: 20px;
  position: relative;
  top: -15px;
  left: 0;
}

.trainingTable .price-value .subtitle {
  color: #666 !important;
  display: block;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}

.trainingTable .price-value-daily {
  background: #fafafa;
  color: #666;
  font-weight: 900;
  padding: 15px 10px;
}

.trainingTable .price-value-daily span {
  color: #666;
  display: inline-block;
  font-size: 40px;
  font-weight: normal;
  line-height: normal;
}

.trainingTable .price-value-daily small {
  font-size: 20px;
  position: relative;
  top: -38px;
  left: 0;
}

.trainingTable .price-value-daily .subtitle {
  color: #666 !important;
  display: block;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}

.navbar-right-course .right-course {
  /* background-color: #0096FF;
   */
}

.navbar-right-course .nav .nav-item {
  /* background-color: #fff; */
	/* border: 1px solid #4f4f4f; */
	/* border: 1px solid #a9a9a9; */
	border: none;
	margin-right: 10px;
    background: #dee5e7;
    padding: 0;
}

.navbar-right-course .nav .nav-item.active-right-course {
  color: black !important;
  background-color: #a0d1f3 !important;
  font-weight: 600;
  border: none;
  padding: 0;
}

.navbar-right-course .nav .nav-item a {
  /* margin-right: 5px; */
}
.software {
  cursor: pointer;
  padding: 0.25rem 0.5rem;
}
.software:hover {
  background-color: #d5dfe9;
}

.navbar-right-course .nav .nav-item .nav-link:hover {
  /* color: #0096FF !important; */
  color: #4e5964 !important;
  font-size: 600 !important;
}

.trainingTablehead .trainingTable-header h3 {
  color: #4e5964 !important;
}

.trainingTable .best-offer {
  background-color: #3498db;
  color: #fff;
  padding: 6px 50px;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  position: absolute;
  top: 20px;
  right: -55px;
  transform: rotate(45deg);
}

.trainingTable .trainingContent ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.trainingTable .trainingContent ul li {
  border-bottom: 1px solid #ededed;
  color: #9999a5;
  padding: 10px 0;
}

.trainingTable .trainingContent ul li:first-child {
  border-top: 1px solid #ededed;
}

.trainingTable .trainingTable-sign-up {
  padding: 25px 0;
}

.trainingTable .btn-block {
  background: #666;
  border-radius: 0px;
  border: 0px none;
  color: #fff;
  width: 50%;
  padding: 10px 5px;
  margin: 0 auto;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.trainingTable .btn-block:after {
  content: "\f090";
  font-family: "FontAwesome";
  padding-left: 10px;
  font-size: 15px;
}

.trainingTable .btn-block:hover {
  background: #282828;
  color: #fff;
}

@media screen and (max-width: 990px) {
  .trainingTable {
    margin-bottom: 20px;
  }
}
.selecting_item {
  /* border: none; */
  width: 50%;
  padding: 10px 10px;
  background-color: white;
  border-radius: 10px;
}
.selecting_item:hover {
  outline: none;
}

/* /select */

.training-details select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='30' viewBox='0 0 24 24' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 13px;

  border: 1px solid #0096ff;
  border-radius: 5px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

/* .training-details{
  display: flex;
	flex-wrap: wrap;
} */

.training-details label {
  color: #4f4f4f;
  text-align: left;
  font-weight: 900;
}

.training-details label:hover {
  text-decoration: none;
  cursor: pointer;
}

.training-selection {
  background-color: #a0d1f3 !important;
}

.disable-div {
  pointer-events: none;
  opacity: 0.4;
  /* background-image: url(/src/Images/loading.gif); */
}

.price-hov {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.25s;
}

.pricing-hov {
  transition: all 0.3s ease 0s;
}

.pricing-hov:hover {
  background-color: #1684d3 !important;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}

.pricing-hov:hover .price-value {
  background-color: #0065ae !important;
}

.pricing-hov:hover .price-value-daily {
  background-color: #0065ae !important;
}

.pricing-hov:hover .fa-usd {
  color: #fff;
}

.pricing-hov:hover .btn-block {
  background: #fff;
  color: #0065ae;
}

.pricing-hov:hover h1,
.pricing-hov:hover h2,
.pricing-hov:hover h3,
.pricing-hov:hover h4,
.pricing-hov:hover h5,
.pricing-hov:hover p,
.pricing-hov:hover b,
.pricing-hov:hover span {
  color: #fff;
}

.pricing-table-col {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
}

.pricingTable .notes {
  margin: 20px 10px;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .pricingTable {
    height: auto;
  }

  .daily .pricingTable-header {
    height: 245px;
  }

  .pricingTable .pricingTable-header h3 {
    font-size: 15px !important;
    text-transform: uppercase;
  }

  .pricingTable .pricingTable-header p {
    font-size: 13px !important;
  }

  .pricingTable .btn-block {
    width: 80%;
  }
  .pricingTable .pricingTable-sign-up {
    padding: 15px 0 !important;
    width: 100%;
    margin: 0 !important;
  }

  .pricingTable .btn-block {
    width: auto !important;
    padding: 7px 15px !important;
    font-size: 15px;
  }
}

.newToBP hr{
  width: 100%;
  height: 1px;
  margin-top: -28px;
  background-color: gray;
  z-index: 1;
}

.newToBP p span{
  background-color: #fff;
  padding: 10px;
}
