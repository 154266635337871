        .freetrailwrapper {
          padding: 40px 0px 30px 0px;
          background-color: #333E63;
        }
        .title h1 {
          font-size: 1.4rem;
          color: #f7f7f7;
          font-family: "KeepCalm", Helvetica, sans-serif;
          
        }
        .waveswrapper {
          position: relative;
          width: 100%;
          height: 150px;
          margin-bottom: -1px;
        }
        .waveswrapper.vertical {
          transform: rotate(180deg);
        }
        .waveswrapper:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background-color: #ffffff !important;
          clip-path: polygon(100% 0, 0 0, 0 100%);
        }
        .waveswrapper:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background-color: #333E63 !important;
          clip-path: polygon(100% 0, 0 100%, 100% 100%);
        }
        
.freetrailwrapperList > div {
    margin: 2px 0px;
    padding: 4px 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    
}
.freetrailwrapperList h6 {
  font-size: .9rem;
  font-weight: 400;
  font-family: "circular_std", Helvetica, sans-serif;
  color: #ffffff;
  padding-top: 5px;
 }
.freetrailwrapperList .icon {
    color: #0096ff;
    font-size: 1rem;
    margin-right: 10px;
 }