.swiper-pagination-bullet {
    width: 32px;
    height: 10px;
    background: #ffffff;
    border: 2px solid #0096ff;
    opacity: 1 !important;
    border-radius: 5px;
    margin-left: 10px;
}
.my-custom-pagination-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #0096ff;
}