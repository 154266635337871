.featureContainer {
    background: #f4fbff;
 }
.featureWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}
 .featureImage img {
    height: 300px;
    width: 100%;
    object-fit: contain;
}
.featureWrapper.reverse > .featureImage {
    order: 1;
}
.featureWrapper.reverse > .featureContent {
    order: 0;
}
.featureContent {
    align-self: flex-start;
    padding: 20px 50px;
}
.featureContentTitle {
    font-size: 2rem;
    font-weight: 600;
    color: #000000;
    letter-spacing: 1.2px;
    padding: 10px 0px;
    font-family: "KeepCalm", Helvetica, sans-serif;
}
.featureContentSubTitle {
    font-size: 1rem;
    color: #212529;
    font-weight: 400;
    font-family: "circular_std", Helvetica, sans-serif;
 }
.featureContentDesc {
    font-size: .7rem;
    color: #212529;
    font-weight: 400;
    margin: 30px 0px;
    line-height: 25px;
    font-family: "circular_std", Helvetica, sans-serif;
}
 .featureContentList h6 {
    font-size: .9rem;
    font-weight: 400;
    font-family: "circular_std", Helvetica, sans-serif;
    color: #000000;
    padding-top: 5px;
}

.list_design{
    font-size: .9rem;
    font-weight: 400;
    font-family: "circular_std", Helvetica, sans-serif;
    color: #000000;
    padding-top: 5px;
}
.featureContentList > div {
    margin: 2px 0px;
    padding: 4px 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.featureContentList .icon {
    color: #0096ff;
    font-size: 1rem;
    margin-right: 10px;
 }

@media only screen and (max-width: 991px) {
    .featureWrapper {
        grid-template-columns: repeat(1, 1fr);
        padding: 30px 0px;
    }
    .featureImage img {
        height: 250px;
    }
 }