.carousel-caption {
    bottom: 281px !important;
}

.carousel-caption h3 {
    font-size: 45px;
    color: #0096FF;
}

.carousel-caption p {
    font-size: 23px;
}

.carousel-item img {
    height: 500px !important;
}

.carousel-caption {
    left: 10%;
    text-align: left;
    display: flex;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto !important;
}

.carousel-caption .caption-title{
    font-size: 80px ;
    color: rgb(89 90 93);
}

.menu-hero-slider .carousel-item {
    min-height: 500px;
    width: 100%;
    background: no-repeat center center scroll;
    background-size: cover;
}

.caption-title .light {
    color: #3597C2;
    font-family: 'KeepCalm', Helvetica, sans-serif;
}


.caption-title .dark {
    color: #3a4053;
    font-family: 'KeepCalm', Helvetica, sans-serif;
}

.caption-title .green {
    color: #51d5d3;
    font-family: 'KeepCalm', Helvetica, sans-serif;
}