@media (max-width: 359px) {
  #data-technology ul.list-marginTop {
    margin-top: 9px;
  }

  #data-technology nav ul li a {
    font-size: 46%;
  }

  #data-technology nav ul li:not(:last-child) {
    margin-right: 0%;
  }

  #data-technology nav ul li:nth-child(2) {
    border-right: 3px solid #000;
    padding-right: 2px;
  }

  #data-technology .navbar-light .navbar-toggler {
    margin-left: -17% !important;
  }

  #data-technology nav ul li:nth-child(3) {
    /* border-right: 3px solid #000; */
    padding-left: 2px;
    /* margin-right: 0px; */
  }
}

@media (min-width: 360px) and (max-width: 446px) {
  #data-technology ul.list-marginTop {
    margin-top: 9px;
  }

  #data-technology nav ul li a {
    font-size: 46%;
  }

  #data-technology nav ul li:not(:last-child) {
    margin-right: 0%;
  }

  #data-technology nav ul li:nth-child(2) {
    border-right: 3px solid #000;
    padding-right: 2px;
  }

  #data-technology .navbar-light .navbar-toggler {
    margin-left: -17% !important;
  }

  #data-technology nav ul li:nth-child(3) {
    /* border-right: 3px solid #000; */
    padding-left: 2px;
    /* margin-right: 0px; */
  }

  .details-nav {
    background-color: #f4f4f4;
  }

  .details-nav .header-text {
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 5px;
  }
  .details-nav .details-nav-items {
    flex-wrap: wrap;
    justify-content: flex-end !important;
  }

  .details-nav ul.details-nav-items li a {
    padding: 4px;
  }
}

@media (min-width: 447px) and (max-width: 538px) {
  #data-technology ul.list-marginTop {
    margin-top: 15px;
  }

  #data-technology nav ul li a {
    font-size: 60%;
  }

  #data-technology nav ul li:not(:last-child) {
    margin-right: 2%;
  }

  #data-technology nav ul li:nth-child(2) {
    border-right: 3px solid #000;
    padding-right: 2%;
  }

  #data-technology .navbar-light .navbar-toggler {
    margin-left: -25% !important;
  }

  .details-nav {
    background-color: #f4f4f4;
  }

  .details-nav .header-text {
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 5px;
  }
  .details-nav .details-nav-items {
    flex-wrap: wrap;
    justify-content: flex-end !important;
  }

  .details-nav ul.details-nav-items li a {
    padding: 4px;
  }
}

@media (min-width: 539px) and (max-width: 575.98px) {
  #data-technology ul.list-marginTop {
    margin-top: 19px;
  }

  #data-technology nav ul li a {
    font-size: 74%;
  }

  #data-technology nav ul li:not(:last-child) {
    margin-right: 2%;
  }

  #data-technology nav ul li:nth-child(2) {
    border-right: 3px solid #000;
    padding-right: 3%;
  }

  #data-technology .navbar-light .navbar-toggler {
    margin-left: -31% !important;
  }

  .details-nav {
    background-color: #f4f4f4;
  }

  .details-nav .header-text {
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 5px;
  }
  .details-nav .details-nav-items {
    flex-wrap: wrap;
    justify-content: flex-end !important;
  }

  .details-nav ul.details-nav-items li a {
    padding: 4px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #data-technology ul.list-marginTop {
    margin-top: 25px;
  }

  #data-technology nav ul li a {
    font-size: 80%;
  }

  #data-technology nav ul li:not(:last-child) {
    margin-right: 2%;
  }

  #data-technology nav ul li:nth-child(2) {
    border-right: 3px solid #000;
    padding-right: 2%;
  }

  #data-technology .navbar-light .navbar-toggler {
    margin-left: -31% !important;
  }

  .details-nav {
    background-color: #f4f4f4;
  }

  .details-nav .header-text {
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 5px;
  }
  .details-nav .details-nav-items {
    flex-wrap: wrap;
    justify-content: flex-end !important;
  }

  .details-nav ul.details-nav-items li a {
    padding: 4px;
  }
  .tech-partner-image {
    width: 80%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #data-technology ul.list-marginTop {
    margin-top: 40px;
  }

  #data-technology nav ul li a {
    font-size: 100%;
  }

  #data-technology nav ul li:not(:last-child) {
    margin-right: 2%;
  }

  #data-technology nav ul li:nth-child(2) {
    border-right: 3px solid #000;
    padding-right: 2%;
  }

  #data-technology .navbar-light .navbar-toggler {
    margin-right: 73% !important;
  }

  #data-technology nav ul li:nth-child(3) {
    /* border-right: 3px solid #000; */
    padding-left: 2px;
    /* margin-right: 0px; */
  }

  .tech-partner-image {
    width: 80%;
    margin: 0 auto;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #data-technology .header-text h3 {
    text-transform: uppercase;
    color: #333e63;
    /* background-color: #F8F9FA; */
    padding: 35px 0px 0px;
  }
  #data-technology ul.list-marginTop {
    margin-top: 40px;
  }

  #data-technology nav ul li a {
    font-size: 91%;
  }

  #data-technology nav ul li:not(:last-child) {
    margin-right: 2%;
  }

  #data-technology nav ul li:nth-child(2) {
    border-right: 3px solid #000;
    padding-right: 20px;
  }
  .tech-partner-image {
    width: 60%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  #data-technology ul.list-marginTop {
    margin-top: 50px;
  }

  .tech-partner-image {
    width: 60%;
    margin: 0 auto;
  }
}
