/* .clearfixAfter::after {
    background-color: #F1F6F6;
    content: "aaa" !important;
    clear: both !important;
    display: block !important;
} */

.navbar {
	width: 100%;
	margin: auto;
	border-bottom: solid 1px rgb(233, 233, 233);
	box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.5),
		-12px 0 8px -4px rgba(31, 73, 125, 0.5);
	padding: 0 1rem;
}

.navbar-light .navbar-toggler {
	/* margin-left: 0% !important; */
	border: none !important;
}
/* 
@media (max-width: 320px) {
  .nav-section .navbar .responsive {
    width: 100%;
  }
}

@media (min-width: 321px) and (max-width: 540px) {
  .nav-section .navbar .responsive {
    width: 80%;
  }
}

@media (min-width: 541px) and (max-width: 1023px) {
  .nav-section .navbar .responsive {
    width: 100%;
  }
}

@media (min-width: 360px) and (max-width: 446px) {
  .navbar-light .navbar-toggler {
    margin: 0px !important;
  }
} */

@media (min-width: 992px) and (max-width: 1064px) {
	#btn-grp .btn-text {
		font-size: 0.7rem;
	}
}

.navbar-brand {
	margin-right: 0rem !important;
	padding: 0 !important;
}

.navbar-brand img {
	height: 60px !important;
}

.carousel-item img {
	height: 900px;
}

.navbar.fixed-top {
	background-color: #eff2fa !important;
}

#drop-menu {
	/* display:block;  */
	/* opacity: 0;   */
	/* visibility: hidden;  */
	/* transition:.3s; 
    margin-top:0;  */
	/* width: 50% !important; */
	/* width: 43rem; */
}
.dropdown-menu-two {
	position: absolute;
	top: 100%;
	left: 180px;
	z-index: 1000;
	/* display: none; */
	opacity: 1 !important;
	display: block;
	float: left;
	min-width: none !important;
	width: fit-content;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: none !important;
	background-clip: none !important;
	border: none !important;
}

.dropdown-menu-two .col-md-3 {
	background-color: #fff !important;
	background-clip: border-box !important;
	/* border: 1px solid black !important; */
	/* border-radius: 0.25rem; */
	padding: 0.5rem 0.5rem;
	margin: 0.125rem 0 0;
}
.nav-item {
	padding-right: 1.2rem;
}

.mv .nav-item {
	padding-right: 0;
}

.title {
	color: #06d5f7;
	/* letter-spacing: 3px; */
	padding-bottom: 7px;
	font-size: 13px;
	font-size: 0.85rem;
	border-bottom: 1px solid #bfe2ff;
}

.subtitle {
	color: #06d5f7;
	letter-spacing: 1px;
	padding-bottom: 7px;
	font-size: 20px;
}

.nav-item-border {
	border-left: 1px solid #17cdc6;
	border-right: 1px solid #17cdc6;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
	outline: none;
	box-shadow: none;
}

.actives {
	/* color: #007bff !important; */
	/* font-weight: bold; */
}

.actives span {
	border-bottom: 2px solid red;
	width: 40%;
	position: absolute;
	left: 20%;
	top: 65%;
	display: none;
}

.navbar-dark .navbar-nav .nav-link {
	color: #0096ff !important;
	padding-right: 1.5rem;
}

.list-unstyled li a {
	color: #0096ff;
	font-size: 0.9rem;
}

.list-unstyled li a:hover {
	color: #17cdc6;
}

.dropdown-menu-two ul li {
	padding: 2px 0px;
}

.navbar-nav {
	padding-left: 30px;
}
.navbar .nav-item:hover .nav-link {
	color: #fff;
}

.navbar .dropdown-menu-two.fade-down {
	top: 80%;
	transform: rotateX(-75deg);
	transform-origin: 0% 0%;
}

.navbar .dropdown-menu-two.fade-up {
	top: 180%;
}

.navbar .subNav-icon:hover .dropdown-menu-two {
}

.navbar .nav-item:hover .dropdown-menu-two {
	opacity: 1;
	visibility: visible;
	top: 100%;
	transform: rotateX(0deg);
	width: fit-content;
	background-color: #f8f9fa;
	padding: 10px;
	-webkit-box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.33);
	box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.33);
}

/* .megamenu-link:hover .dropdown-menu{
    width: 60% !important;
} */

.dropdown-toggle::after {
	vertical-align: 0rem !important;
}

.dropdown-large {
	padding: 20px !important;
}

.dropdown-item {
	padding: 0.5rem 5.5rem !important;
}

.btn.btn-success {
	color: #fff !important;
	background-color: #0096ff !important;
	border-color: #0096ff !important;
}

.btn.btn-success:hover {
	color: #fff !important;
	background-color: #09a2ad !important;
	border-color: #09a2ad !important;
}

.btn.btn-outline-success {
	color: #0096ff !important;
	border-color: #0096ff !important;
}

.btn.btn-outline-success:hover {
	color: #fff !important;
	background-color: #0096ff !important;
	border-color: #0096ff !important;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
	box-shadow: none !important;
}

#btn-grp .btn-grp-1 {
	transform: skew(-15deg);
	border-radius: 150px 0px 0px 239px;
}

#btn-grp .btn-grp-1 .btn-text {
	transform: skewX(15deg);
}

#btn-grp .btn-grp-2 {
	transform: skew(-15deg);
	border-radius: 0px;
}

#btn-grp .btn-grp-2 .btn-text {
	transform: skewX(15deg);
}

a.megamenu-link {
	display: inline-block;
	padding: 5px 5px;
}

a.megamenu-link:hover {
	background: #f0f0f0;
	border-radius: 4px;
}

.nav-section .navbar-nav .nav-link {
	font-size: 1.25rem;
}

nav.mv.navbar {
	padding: 0;
}

nav.mv.navbar .nav-head {
	padding: 5px 10px;
}

nav.mv.navbar .navbar-collapse {
	border-top: 3px solid #7fbaea;
	background: #efefef;
	height: calc(100vh - 70px);
	overflow: auto;
	padding: 0 15px;
}

nav.mv.navbar .primary-nav-item {
	border-bottom: 1px solid rgb(225 225 225);
}

.primary-nav-item-btn {
	border: none;
	background: transparent;
	padding-left: 50px;
}

nav.mv.navbar .form-inline {
	position: absolute;
	bottom: 0;
	margin-left: 15px;
	width: 100%;
}

.navbar .nav-item .drop-mobile {
	width: 100%;
	height: auto;
	overflow: auto;
}

/* .desktop.navbar li.nav-item:hover,
.desktop.navbar li.nav-item:focus-within {
  background: red;
  cursor: pointer;
} */

/* .desktop.navbar li.nav-item:hover,
.desktop.navbar li.nav-item:focus-within {
  background: red;
  cursor: pointer;
}

.desktop.navbar .navbar-nav .nav-link:before {
  position: absolute;
  height: 2px;
  height: 2px;
  width: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 22px;
  background: #0096ff;
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 100% 50%;
} */

.desktop.navbar .navbar-nav .nav-link:before {
	position: absolute;
	height: 2px;
	height: 2px;
	width: 55%;
	left: -15%;
	right: 0;
	margin: 0 auto;
	bottom: 22px;
	background: #0096ff;
	display: block;
	content: "";
	transform: scaleX(0);
	transition: transform 250ms ease-in-out;
	transform-origin: 100% 50%;
}

.desktop.navbar li.nav-item:hover .nav-link:before,
.desktop.navbar li.nav-item:focus-within .nav-link:before {
	transform: scaleX(1);
	transform-origin: 0% 50%;
}

/* .desktop.navbar .navbar-nav .nav-link:hover::before {
  transform: scaleX(1);
  transform-origin: 0% 50%;
} */

.desktop.navbar .navbar-nav .nav-link:active::before {
	transform: scaleX(1);
	transform-origin: 0% 50%;
}

.desktop.navbar .navbar-nav .nav-link.actives:before {
	position: absolute;
	width: 55%;
	left: -15%;
	right: 0;
	margin: 0 auto;
	bottom: 22px;
	background: #d10000;
	display: block;
	content: "";
	transform: scaleX(1);
}

.dropdown-title {
	position: relative;
	width: 60px;
	background-color: #c5c4c4;
	min-height: 250px;
}

.dropdown-title h3 {
	font-size: 1.5rem;
	color: #ffffff;
	text-transform: uppercase;
	letter-spacing: 3px;
	position: absolute;
	bottom: 0;
	left: 0;
	top: 95%;
	margin-left: 28%;
	/* margin: 0 auto; */
	transform-origin: 0 0;
	transform: rotate(270deg);
}
.for-gap{
	
}
/* alvi */
.career-div {
	margin-top: 10px;
	
	/* margin-top: 4px; */
	
	color: #0096ff !important;
	padding: 15px 8px !important;
	font-size: 1.25rem !important;
	
  line-height: 1.5 !important;
  
  text-align: left;
  font-family: "circular_std", Helvetica, sans-serif;
  /* font-weight: bold !important; */
  
	
  }
  
  .border-none {
	border: none;
  }
  
  .text-decoration-none {
	text-decoration: none;
  }
  
  .career-link {
	text-decoration: none;
	position: relative;
	font-size: 1.25rem !important;
	font-family: "circular_std", Helvetica, sans-serif !important;
  }
  
  .career-link::after,
  .hover-line {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	
	bottom: -6px;
	left: 0;
	transform: scaleX(0);
	transform-origin: 0 50%;
	transition: transform 250ms ease-in-out, background-color 0.3s ease-in-out;
	margin-top: 2px; 
  }
  /* background-color: #0096ff; */
  
  .career-link:hover::after {
	transform: scaleX(1);
  }
  
  .career-link {
	
	text-decoration: underline;
  }
  
  .career-link.active {
	border-bottom: 2px solid #dc3545;
	color: #0096ff !important;
	padding-bottom: 8px;
  }
  
  .career-link:not(.active):hover {
	border-bottom: 2px solid #0096ff ;
	padding-bottom: 8px;
   
  }
  
  .active-line.active {
	border-bottom: 2px solid #dc3545;
	
  } 
  
  
  @media (max-width: 992px) {
	  .career-div {
		margin-right: 0;
		 
	  }
	}
	
   
	@media (max-width: 768px) {
	  .career-div {
		margin-right: 0; 
		text-align: start;
		margin-left: -4px;
		margin-top: -10px;
		font-size: 1.25rem;
		color:rgba(0,0,0,.5) !important ;
		
	  }
	  a{
		color: rgba(0,0,0,.5) !important;
		
	  }
	  .career-div  :hover{
		color: #dc3545 !important;
		text-decoration: none !important;
		border-bottom:  none !important;
	  }
	  .career-div a.active-link {
		color: #0096ff !important;
		
	  }

	}
  
	.active-line {
		/* Add styles for the active-line here */
		/* For example, you can set the line's color and height */
		border-bottom: 2px solid #dc3545;
		height: 2px;
	  }
	  .hover-line {
		/* Add styles for the hover-line here */
		/* For example, you can set the line's color and height */
		border-bottom: 2px solid #0096ff;
		height: 2px;
	  }
	  .career-div .career-link {
		color: #0096ff; /* Your desired text color */
		text-decoration: none; /* Remove underline */
	  }
	  
	 
	  .dropdown-content {
		margin-top: 28px;
		max-height: 260px;
		width: 400px;
		display: none;
		position: absolute;
		padding: 5px;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
		background-color: #f8f9fa;
	}
	
	.career-div:hover .dropdown-content {
		display: block;
	}
	.company-div{
		background-color: #c5c4c4;
		padding: 20px;
		height: 240px;
		
		
	}
	.company-div h3{
		color: #ffffff;
		
	}
	.rotate-text {
		transform: rotate(270deg);
		transform-origin: left top;
		white-space: nowrap;
		position: absolute;
		top: 100%;
		left: 0;
		padding: 20px;
		margin-top: 20px;
		
	}
	.dp-color{
		color: #0096ff;
		font-size: 14px;
	}
	.dp-color:hover{
		color: #06d5f7;
	}
	.by-function{
		color: #2ADAF2;
		
	}
	.by-function::after {
		content: '';
		display: block;
		width: 100%;
		border-bottom: 1px solid #7fbaea; /* Adjust the color and style as needed */
		/* margin-top: 4px; */
		 /* Adjust the margin as needed */
	  }
	  
	  
	  
/* reference :  https://css-tricks.com/snippets/css/text-rotation/ */
