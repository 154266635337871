.form-style .data-form-label{
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #0096ff;
    margin-top: 10px;
}
.form-style .input-form{
    width: 100%;
    margin-top: 5px;
    /* padding-top: 10px !important; */
    padding: 5px;
    background-color: #f4f4f4;
    border: 0px;
    border-bottom: 1px solid gray;
    /* border-radius: 10px; */
}

textarea:focus, input:focus{
    outline: none;
}