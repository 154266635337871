/* .submenuHeaderItemTab .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: darkgray !important;
    background-color: transparent !important;
    font-weight: bold !important;
    font-size: 21px !important;
}

.submenuHeaderItemTab .nav-pills a {
    color: #000;
    font-weight: bold !important;
    font-size: 21px !important;
}

.submenuHeaderItemTab .nav-link:focus, .nav-link:hover {
    color: darkgray !important;
} */

.submenuMainItemTab #solution-tab ul {
  list-style: none;
  padding-left: 0px;
}

.submenuMainItemTab .sub-menu-left,
.submenuMainItemTab .sub-menu-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submenuMainItemTab #solution-tab ul li {
  color: darkgray;
}

.submenuMainItemTab .sub-menu-list a {
  color: black;
}

.submenuMainItemTab #solution-tab ul li i {
  color: gray;
  border-radius: 50%;
  padding: 5px;
  margin-bottom: 9px;
  margin-right: 9px;
}

.submenuFooterItemTab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: red !important;
  background-color: transparent !important;
  font-weight: bold !important;
  font-size: 21px !important;
}

.submenuFooterItemTab .nav-pills a {
  color: #000;
  font-weight: bold !important;
  font-size: 21px !important;
}

.submenuFooterItemTab .nav-link:focus,
.nav-link:hover {
  color: red !important;
}

/* .tab-circle p {
    color: transparent;
    background-color: lightgray;
    width: 9%;
    height: 17px;
    border-radius: 50%;
    position: relative;
    left: 45%;
} */

/* .submenuHeaderItemTabBorder:before {
    content: ' ';
    background: #d4d9df;
    border-bottom: 1px solid  #d4d9df;
    display: inline-block;
    position: absolute;
    left: 44px;
    width: 2px;
    height: 100%;
    z-index: 400;
} */

.list-group-item {
  border: none;
  padding: 0.3rem 1.25rem;
  color: #000 !important;
}

.list-group-item i {
  margin-right: 10px;
}

.active {
  color: red !important;
  font-weight: 600;
}

.actived {
  color: red !important;
  font-weight: 600;
  background-color: white !important;
}

/* .submenuFooterItemTab{

} */

.submenuFooterItemTab ul.nav {
  margin-top: 15px;
  border-top: 1px solid #c0c0c0;
  justify-content: flex-end;
}

.submenuFooterItemTab .nav-item .nav-link {
  font-size: 18px !important;
  background: #f3f3f3;
  border-radius: 0 0 10px 10px;
}

.submenuFooterItemTab .nav-item .nav-link.actived {
  font-weight: normal;
  border: 1px solid #c0c0c0;
  border-top: none;
  margin-top: -1px;
  background-color: #fff;
}

.submenuFooterItemTab .navbar {
  box-shadow: none;
  border: none;
}

.submenuFooterItemTab .navbar ul.nav {
  width: 100%;
}

@media (max-width: 414px) {
  #pills-tab .nav-item .nav-link {
    font-size: 23px !important;
    margin: 5px;
  }
}

@media (min-width: 415px) and (max-width: 991px) {
  #pills-tab .nav-item .nav-link {
    font-size: 12px !important;
    /* margin: 5px; */
  }
}

@media only screen and (min-width: 1921px) {
  .submenuFooterItemTab .nav-item .nav-link.actived {
    margin-top: -2px;
  }
}

@media only screen and (max-width: 767.98px) {
  .submenuMainItemTab .sub-menu-left,
  .submenuMainItemTab .sub-menu-right {
    display: flex;
    justify-content: left;
    align-items: left;
  }

  .submenuHeaderItemTab li {
    line-height: 1.2em;
  }

  .submenuFooterItemTab ul.nav {
    justify-content: space-evenly;
  }

  .submenuFooterItemTab .nav-item {
    padding-right: 5px;
  }

  .submenuFooterItemTab .nav .nav-item .nav-link {
    padding: 8px 15px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .submenuFooterItemTab ul.nav {
    justify-content: space-evenly;
  }

  .submenuFooterItemTab .nav-item {
    padding-right: 5px;
  }

  .submenuFooterItemTab .nav .nav-item .nav-link {
    padding: 10px 20px;
  }
}
