.blog-top .topImg img {
	width: 9%;
}

.blog-top .navbar-light .navbar-nav .nav-link {
	color: #0096ff !important;
	padding: 10px 31px;
	/* font-weight: bold; */
}

.blog-top .navbar-light .navbar-nav .nav-link:before {
	position: absolute;
	height: 2px;
	width: 100%;
	left: 0;
	bottom: 18px;
	background: #0096ff;
	display: block;
	content: "";
	transform: scaleX(0);
	transition: transform 250ms ease-in-out;
	transform-origin: 100% 50%;
}

.blog-top .navbar-light .navbar-nav .nav-link:hover::before {
	transform: scaleX(1);
	transform-origin: 0% 50%;
}

.blog-top #actives:before {
	position: absolute;
	height: 2px;
	width: 100%;
	left: 0;
	bottom: 18px;
	background: #d10000;
	display: block;
	content: "";
	transform: scaleX(1);
}

.nav-head {
	color: #0096ff !important;
	padding: 10px 31px;
	font-weight: 800;
}

.blog-top #actives {
	color: #000 !important;
}

@media (max-width: 991px) {
	.blog-header {
		width: 70%;
	}
}

@media (min-width: 992px) {
	.blog-top .navbar-light .navbar-nav .nav-link {
		color: #0096ff !important;
		position: relative;
		display: inline-block;
	}

	.blog-top .navbar-expand-lg .navbar-nav .nav-link {
		padding: 1.5rem 0.5rem !important;
		margin-right: 1rem;
	}
}

/*** navbar and footer none ***/
/* .nav-section {
    display: none;
}

.contact-request {
    display: none;
}

.insights {
    display: none;
}

.resources {
    display: none;
}

.more-ways {
    display: none;
}

.footer-main {
    display: none;
} */
