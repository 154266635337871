@media (max-width: 575.98px) {
  #footer .contact-request .contact-container .contact-right {
    margin-top: -282px;
    margin-left: 9px;
    text-align: center;
  }

  #footer .footer-main .footer-logo {
    padding: 0;
    margin-bottom: 20px;
  }

  #footer .footer-main .footer-logo a {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #footer .contact-request .contact-container .contact-right {
    margin-top: -282px;
    margin-left: 9px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .insights-header {
    margin-bottom: 2rem;
    text-align: center;
  }

  .resources-header {
    text-align: center;
  }
}
