ul.common-section-list li {
  list-style: none;
}

.training-item h4 {
  font-size: 18px;
}

.detail-section-link {

  padding: 0 5px 0 5px;
  text-decoration: none;
  background: #0096ff;
  transform: skew(-15deg);
}

.detail-section-link .link-text {
  transform: skewX(15deg);
  color: #fff;
}

.detail-section-link:hover {
  color: #17cdc6;
}

#Training .list-describe .title {
  font-size: 1.5rem;
  border: none;
}

#Training .training-overview {
  margin-bottom: 20px;
  text-align: center;
}

#Training .section-title {
  margin-bottom: 0;
}

.read-more-btn{
  padding: 0 5px;
  line-height: 1.2rem;
}

.skew-1{
  transform: skew(-15deg);
  border-radius: 0px;
}

.skew-1 .btn-text{
  transform: skew(15deg);
  text-decoration: none;;
}