.contact .contact-container,
.contact .request-container {
	height: 100%;
}

.contact-request .contact-container {
	background-color: #f5f5f5;
}

.contact .request-container {
	background-color: #0096ff;
	color: #2e2e2e;
	min-height: 299px;
	padding: 3rem;
	position: relative;
}

.contact .request-container p {
	color: #f2f2f2;
}

.contact-left img {
	width: 100%;
	object-fit: cover;
	padding-bottom: 3rem !important;
	padding-top: 3rem !important;
}

@media only screen and (max-width: 992px) {
	.contact-request .insights-header h3 {
		font-size: 20px;
	}
}

@media (min-width: 991px) {
}

@media (max-width: 575px) {
	.contact-left img {
		width: 100%;
		object-fit: cover;
		padding-bottom: 1rem !important;
		padding-top: 1rem !important;
	}

	.contact .request-container {
		padding: 2rem;
	}
}
