.Consultation-section{
    padding-top: 50px;
    padding-bottom: 50px;
}

.Consultation-section .Title{
    padding: 0px 10px 15px 0;
}

.Consultation-section .subTitle {
    font-size: 20px;
    padding-bottom: 20px;
}

.Consultation-section .details-section{
    margin-top: 50px;
}

.Consultation-section .image-section {
    overflow: hidden;
}

.Consultation-section .image-section img {
    width: 100% !important;
    height: 450px !important;
    object-fit: contain;
}

.Consultation-section .MuiGrid-item{
    margin-bottom: 30px !important;
}

.consultation-overview{
    background-color: transparent;
    font-size: 16px;
    text-align: center;
}

.consultation-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.consultation-content .overview{
    font-size: 16px;
    padding-bottom: 20px;
    word-break: break-word;
}


.consultation-content .consultation-items{
    font-size: 20px;
    padding-bottom: 20px;
}
