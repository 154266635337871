@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

@font-face {
	font-family: "KeepCalm";
	src: local("KeepCalm"), url(./fonts/KeepCalm-Medium.ttf) format("truetype");
}
@font-face {
	font-family: "circular_std";
	src: local("KeepCalm"), url(./fonts/KeepCalm-Book.ttf) format("truetype");
}

@font-face {
	font-family: "circular_std";
	src: local("Circular_std"),
		url(./fonts/CircularStd-Book.ttf) format("truetype");
}

html {
	scroll-behavior: smooth;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.container-fluid {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.row {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.col-md-12 {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.light-blue-bg {
	background-color: #f4fbff;
}

.light-blue-bg .MuiPaper-root {
	background-color: transparent !important;
}

.main-content {
	margin-top: 85px;
	width: 100%;
	overflow-x: hidden;
}

body {
	font-family: "circular_std", Helvetica, sans-serif;
	font-size: 16px;
}

.main-section {
	padding-top: 85px;
	/* alvi */
	/* text-align: justify !important; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "KeepCalm", Helvetica, sans-serif;
	color: #000;
}

p,
span {
	font-family: "circular_std", Helvetica, sans-serif;
	color: #595959;
}

li,
td {
	font-family: "circular_std", Helvetica, sans-serif;
	color: #595959;
}

.section-title {
	font-family: "KeepCalm", Helvetica, sans-serif;
	font-size: 1.7rem;
	color: #000;
	padding: 20px 0 30px;
	line-height: normal;
	margin-bottom: 0;
}

.section-sub-title {
	font-family: "KeepCalm", Helvetica, sans-serif;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: #000;
}

.section-heading-large {
	font-family: "KeepCalm", Helvetica, sans-serif;
	font-size: 2.5rem;
	color: #000;
}

.section-heading-medium {
	font-family: "KeepCalm", Helvetica, sans-serif;
	font-size: 2rem;
}

.section-heading-small {
	font-family: "KeepCalm", Helvetica, sans-serif;
	font-size: 1.5rem;
}

.btn-primary {
	background-color: #0096ff !important;
	border-color: #0096ff !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
	background-color: #09a2ad !important;
	border-color: #09a2ad !important;
}

.primary-nav-expanded {
	overflow: hidden;
}

.btn-skew {
	transform: skew(-15deg);
	border-radius: 0px;
}

.btn-skew > .btn-text {
	transform: skewX(15deg);
}

#root {
	overflow-x: hidden;
}

.form-error-message {
	color: rgb(196, 35, 35) !important;
	font-weight: normal;
	font-size: 0.7rem;
	text-align: left;
	margin-top: -20px;
}

.form-error-message-v2 {
	color: rgb(196, 35, 35) !important;
	font-weight: bold;
	font-size: 0.8rem;
	text-align: left;
	margin-top: 0;
}

.form-error-message-v3 {
	color: rgb(196, 35, 35) !important;
	font-weight: bold;
	font-size: 0.8rem;
	text-align: left;
	margin-top: -18px;
}

.form-error-message-v4 {
	color: rgb(196, 35, 35) !important;
	font-weight: bold;
	font-size: 0.8rem;
	text-align: left;
	margin-top: -39px;
}

.form-error-message-v5 {
	color: rgb(196, 35, 35) !important;
	font-weight: bold;
	font-size: 0.8rem;
	text-align: left;
	margin-top: -50px;
}

.modal-header .close span {
	background: #000;
	color: #fff;
	padding: 2px 11px 4px;
	border-radius: 50%;
	font-family: "Roboto", Helvetica, sans-serif;
}

/*=========================== loading-section ===================================*/

.loading-section {
	/* margin-top: 85px;
  height: calc(100vh - 85px); */
}
.loading-section .overlay {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: #ffffff;
	z-index: 9999;
}

.loading-section .overlay__inner {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.loading-section .overlay__content {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

.loading-section .spinner {
	width: 75px;
	height: 75px;
	display: inline-block;
	border-width: 2px;
	border-color: rgba(255, 255, 255, 0.05);
	border-top-color: #fff;
	animation: spin 1s infinite linear;
	border-radius: 100%;
	border-style: solid;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.complete-title {
	font-size: 1.7rem;
	color: rgb(1, 141, 31);
}

.complete-sub-title {
	font-size: 1.5rem;
	color: rgb(48, 48, 48);
}

/* .box input[type="text"],
.box textarea[type="text"],
.box input[type="email"],
.box input[type="submit"],
.box select {
	font-size: 1rem !important;
	padding: 0.5rem !important;
	margin-top: 1rem;
	background: #f3f3f3;
	border-radius: 4px 4px 0 0;
}

.box select {
	font-size: 1rem !important;
	padding: 0.7rem 0.5rem !important;
	margin-top: 0rem;
	background: #f3f3f3;
	border-radius: 4px 4px 0 0;
} */

/*=========================== bootstrap override ===================================*/
.btn-primary.focus,
.btn-primary:focus {
	box-shadow: none !important;
}

.btn-success.focus,
.btn-success:focus {
	box-shadow: none !important;
}

/*=============================  utilities  ========================================= */

.m-t-10 {
	margin-top: 10px;
}

.m-t-15 {
	margin-top: 15px;
}

.m-t-30 {
	margin-top: 30px;
}

.m-b-10 {
	margin-bottom: 10px;
}

.m-b-15 {
	margin-bottom: 15px;
}

.m-b-30 {
	margin-bottom: 30px;
}

/*============================== Responsive css ================================*/

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {
	.modal-title {
		font-size: 1rem;
	}

	.section-heading-large {
		font-size: 1.5rem !important;
	}

	.section-title {
		font-family: "KeepCalm", Helvetica, sans-serif;
		font-size: 1.2rem;
		color: #000;
		line-height: 2rem;
	}

	.section-sub-title {
		font-family: "KeepCalm", Helvetica, sans-serif;
		font-size: 1rem;
		color: #000;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.modal-title {
		font-size: 1.5rem;
	}

	.section-sub-title {
		font-family: "KeepCalm", Helvetica, sans-serif;
		font-size: 1rem;
		line-height: 1.5rem;
		color: #000;
	}

	.sm-w-100 {
		width: 100%;
	}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
	.modal-title {
		font-size: 2rem;
	}

	.section-sub-title {
		font-family: "KeepCalm", Helvetica, sans-serif;
		font-size: 1rem;
		color: #000;
	}

	.md-w-50 {
		width: 50%;
	}
}

@media (max-width: 767px) {
	.container,
	.container-md,
	.container-sm {
		max-width: 90%;
	}
	.sm-w-100 {
		width: 100%;
	}
	.section-title {
		font-size: 1.7rem;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}


.color-1{
	background-color: rgb(236, 236, 236);
	padding: 50px 10px;
}

.color-2{
	background-color: rgb(228, 234, 255);
	padding: 50px 10px;
}