.container-alljobs{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    font-family: "KeepCalm", Helvetica, sans-serif;
    
    @media (max-width: 768px) {
        grid-template-columns: 100%; /* Switch to a single column for smaller devices */
        justify-items: center; /* Center the card horizontally */
    }
}
/*  */
