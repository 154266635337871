.bp-homepage-hero-section {
	margin-top: 0;
	min-height: 50vh;
}

.bp-homepage-service-section {
}

.bp-homepage-interconnectivities-section {
}

.bp-homepage-uniqueSolution-section {
}

.bp-homepage-modernTech-section {
}

.bp-homepage-personalizedService-section {
}

.bp-homepage-Consultation-section {
}

.bp-homepage-CoreValues-section {
}

.bp-homepage-BpTraining-section {
}

.bp-homepage-personalizedService-section {
}
