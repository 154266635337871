.contact-us-form {
	color: dimgray;
}

.contact-us-form .contact-row {
	/* height: 285px !important; */
	/* margin-bottom: -30px; */
}

.contact-row {
	text-align: left;
}
.contact-row .left,
.contact-row .right {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
}

.contact-row .left .form-group {
	margin-bottom: 0;
}

.contact-us-form h1 {
	padding-top: 59px;
	padding-bottom: 39px;
}

.contact-us-form textarea {
	width: 100% !important;
	height: 302px;
	resize: none;
	padding: 15px !important;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.contact-us-form textarea:focus {
	outline: 0;
	border-color: darkgray;
}

.contact-us-form .form-control:focus {
	box-shadow: none !important;
	border-color: darkgray;
}

.contact-us-form .card-body {
	padding: 2rem 1rem;
}

label {
	display: inline-block;
	margin-bottom: 0.3rem !important;
}

.captcha-checkbox {
	margin-top: 20px;
}
