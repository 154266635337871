.subscrption-section {
  background-color: #333e63;
  padding: 20px 0px;
}

p.subscription-title {
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.subscription-form {
  width: 50%;
  margin: 0 auto;
}

.spinner .fa {
  color: #fff;
}
