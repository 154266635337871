         .dashboardSection {
            margin: 50px 0px 50px 0px;
          }
          .dashboard__demoContainer {
            margin-top: 20px;
            position: relative;
            height: 400px;
            width: 100%;
          }
          .dashboard__demoContainer img {
            height: 100%;
            width: 100%;
          }
          .dashboard__demoContainer__embededCode {
            position: absolute;
            width: 100%;
            top: 37px;
            left: 50px;
            height: 100%;
          }
          .dashboard__demoContainer__embededCode iframe {
            height: 75%;
            width: 72%;
          }
          @media screen and (min-width: 400px) {
            .dashboard__demoContainer__embededCode {
              left: 54px;
            }
          }
          @media screen and (min-width: 485px) {
            .dashboard__demoContainer__embededCode {
              left: 67px;
            }
          }
          @media screen and (min-width: 522px) {
            .dashboard__demoContainer {
              height: 400px;
            }
            .dashboard__demoContainer__embededCode {
              top: 37px;
              left: 73px;
            }
          }
          @media screen and (min-width: 768px) {
            .dashboard__demoContainer {
              height: 500px;
            }
            .dashboard__demoContainer__embededCode {
              top: 45px;
              left: 97px;
            }
          }
          @media screen and (min-width: 992px) {
            .dashboard__demoContainer__embededCode {
              left: 132px;
            }
          }
          @media screen and (min-width: 1200px) {
            .dashboard__demoContainer {
              height: 650px;
            }
            .dashboard__demoContainer__embededCode {
              top: 58px;
              left: 158px;
            }
          }
          @media screen and (min-width: 1400px) {
            .dashboard__demoContainer__embededCode {
              left: 182px;
            }
          }

          .dashboard__demoContainer__frame{
            border: 25px solid black;
            border-radius: 10px;
            height: 100%;
            width: 100%;
          }
          .dashboard__demoContainer__frame iframe{
            height: 100%;
            width: 100%;
          }