.transform {
	font-size: 17px;
	text-transform: capitalize;
	color: #000000;
	font-weight: 500;
	margin-top: 10px;
}

/* alphabet section  */

.alphabets {
	margin: 15px 0px;
}

.alphabet-meaning {
	display: flex;
	justify-content: start;
}

.alphabet-meaning .alphabet {
	height: 25px;
	width: 25px;
	border: 1px solid #0096ff;
	border-radius: 50%;
	color: #0096ff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
}

/* benifit-as-a-partner */

.benifit-as-a-partner {
	background-color: #333e63;
	padding: 140px 0;
}

.about-us-list {
	padding: 140px 0;
}

.benifit-as-a-partner h2 {
	color: #bfe6ed;
	font-size: 35px;
	font-weight: 500;
	/* text-transform: capitalize; */
}

.text-color{
	color: #f1f3f3;
	font-weight: 500;
	
	/* text-transform: capitalize; */
}

.text-color1{
	color: #f1f3f3;
	font-weight: 500;
	text-align: center;
	/* text-transform: capitalize; */
}

/* benifit-as-a-partner-section */
.benifit-as-a-partner-section {
	margin-top: 20px;
}



/* .benifit-as-a-partner-section h5{
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
} */
.benifit-as-a-partner-section p {
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
}

.benifit-as-a-partner-section h5 {
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
}

/* .about-us-heading {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
} */

/* .about-break {
  height: 2px;
  width: 20px;
  background-color: #0096ff;
  margin: 0px auto;
} */

.about-us-heading span {
	font-size: 17px;
	font-weight: 400;
	padding-top: 10px;
	border-top: 5px solid #0096ff;
}

.about-us-heading h2 {
	font-size: 2.2rem;
	line-height: 1.5;
	font-weight: 600;
	margin-top: 10px;
	text-transform: capitalize;
}

.about-us-core-values {
	position: relative;
}

/* .core-values-bg-transform {
	position: absolute;
	top: 20%;
	right: 5%;
	font-size: 45px;
	color: #0095ff5d;
} */

.core-values-bg-transform {
	position: absolute;
	right: 0;
	top:-20%;
	font-size: 100px;
	color: #0095ff5d;
}
