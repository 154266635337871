.first-title {
    font-size: 2.5rem !important;
    line-height: 3.5rem;
    text-transform: capitalize;
    color: #101c33;
    font-family: "KeepCalm", Helvetica, sans-serif;
    font-weight: 500;
    
    @media (max-width: 768px) {
        font-size: 30px !important;; /* Switch to a single column for smaller devices */
    }
}
    /* color: #101c33; */
    

.first-title-recruitement{
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000000;
    background-color: transparent;
    border: none;
    font-family: "KeepCalm", Helvetica, sans-serif;
    margin: 20px 0;
}
.second-title {
    font-family: "KeepCalm", Helvetica, sans-serif;
    color: #000;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .second-title {
        font-size: 1.2rem; /* Adjust the font size for smaller screens */
    }
}

/* Media query for screens smaller than 576px */
@media (max-width: 576px) {
    .second-title {
        font-size: 1rem; /* Further adjust the font size for even smaller screens */
    }
}

.paragraph-title {
    font-family: "circular_std", Helvetica, sans-serif;
    color: #595959;
    font-weight: 400;
    /* line-height: 1.5; */
    font-size: 16px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 0;
    margin-bottom: 1rem;
}
.benefit-title{
    font-family: "KeepCalm", Helvetica, sans-serif;
    font-size: 1.7rem;
    color: #000;
    padding: 20px 0 30px;
    line-height: normal;
    margin-bottom: 0;
}
.sounding-color {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-family: "KeepCalm", Helvetica, sans-serif;
    font-size: 1.25rem
}
.career-header-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
}

.header-section {

    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.open-rules-section-container {
    display: grid;
    grid-template-columns: 9fr 3fr;
    @media (max-width: 768px) {
        grid-template-columns: 12fr; /* Switch to a single column for smaller devices */
    }
}

#dep-names {
    height: 50px;
    width: 100%;
    background-color: #FFFFFF;
    color: black;
    font-weight: 200;
    font-family: "circular_std", Helvetica, sans-serif;
    
}

.job-post {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    font-family: "KeepCalm", Helvetica, sans-serif;
    /* font-size: 1.25rem; */

    /* Add a media query for smaller devices (e.g., screens with a maximum width of 768px) */
    @media (max-width: 768px) {
        grid-template-columns: 100%; /* Switch to a single column for smaller devices */
        justify-items: center; /* Center the card horizontally */
    }
}


.card-design {
    height: 500px;
    width: 100%;
    border: 1px solid black;
    padding-left: 50px;


}


.card {
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.card-gap {
    padding: 6px; /* Adjust the margin as per your preference */
}

.title-button {
    font-size: 24px;
    margin-bottom: 5px;
    color: #fff !important;
    border: none;
    border: 30px;
    cursor: pointer;
    border-radius: 50px;
    padding: 10px 20px;
    background-color: #0096FF;
}



.for-top-gap {
    margin-top: 25px;
}

.location-icon {
    margin-right: 5px;

}

.learn-more {
    margin-top: 25px;
}

.recruitment-process {
    background-color: #FFFFFF;
    /* background-color: #f4fbff; */
    /* background-color: rgb(235 238 246); */
    /* rgb(235 238 246) */
}



.heading-part {
    text-align: center;
    padding-top: 60px;
}

.heading-part p {
    margin-top: 30px;
}

.process-part-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.for-serial {
    height: 20px;
    width: 20px;
    background-color: #0096FF;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 15px;
    /* margin-left: 10px; */


}

.for-span {
    color: #FFFFFF;

}

.part1 {
    padding: 30px;
}

.benefits-section {
    background-color: #333e63;
    
    
    padding: 40px;
}
.text-white{
color: #ffffff !important;
}

.benefit-section-container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 10px;
}
.benefit-item {
    
    grid-column: span 3; /* This will span across all three columns */
  text-align: center;
  }

/* Add a media query for smaller devices (e.g., screens with a maximum width of 768px) */
@media (max-width: 768px) {
    .benefit-section-container {
        grid-template-columns: 1fr; /* Show one item per row on smaller devices */
        gap: 0px 0px;
    }
    
    .job-title {
        flex-direction: column;
    }
   
    
    .text-div p {
        padding: 0px;
    }
    .benefit-item {
        grid-column: span 1; /* On smaller screens, it will span only one column */
      }
}

.img-div {
    margin: 20px 10px;
}

.text-div {
    padding: 20px 10px;
}

.text-div p {
    padding-top: 20px;
}


.section {
    position: relative;
    margin-top: -35px;
}

.scroll-indicator {
    position: absolute;
    top: 50%;
    right: 370px;
    transform: translateY(-50%);
    animation: moveUpDown 1s infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(10px);
    }
}

.round-shape {
    width: 30px;
    height: 30px;
    background-color: #0096FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chevron-icon {
    color: white;
}

.explore-more-btn-dsgn {
    margin-top: 40px;
}
.key-item {
    padding: 15px;
    background-color: rgb(235 238 246);
    /* background-color: #fff; */
    border-radius: 10px;
    
    text-align: center!important;
}

.key-img{
    height: 50px;
    width: auto;
    margin: 5px 0;
}
.key-title {
  font-size: 20px;
    
    color: #000;
    font-family: "KeepCalm", Helvetica, sans-serif;
    margin: 10px 0 5px 0;
}
.key-desc {
   font-size: 18px;
    color: #3f3f3f;
    margin: 15px 0 5px 0;
    
}

/* benefit */

.separator_dark::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #FFFFFF !important;
    clip-path: polygon(100% 0, 0 0, 0 100%);
}

.vertical.separator_dark {
    transform: rotate(180deg);
    
}


.about-section{
    background-color: #FFFFFF;
    /* background-color: rgb(235 238 246); */
}
.about-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.no-decoration {
    text-decoration: none !important;
}

.first-title-departments{
    
    font-size: larger;
    font-weight: 500;
    font-size: 1rem !important;
    line-height: 3.5rem;
    text-transform: capitalize;
    color: #101c33;
    font-family: "KeepCalm", Helvetica, sans-serif;
    font-weight: 500;
}
.for-option{
    padding: 5px !important;
}
.dropdown-style {
    margin: 10px !important;
    padding: 15px !important;
    font-size: 14px !important;
    border: 2px solid red !important;
    
}

.details-text {
    font-weight: bold; /* Example: make it bold */
    color: #007bff; /* Example: set a different color */
    /* Add other CSS styles as needed */
  }
  .category{
    text-align: end !important;
    font-size: 12px !important;
    padding: 0px !important;
    font-family: "KeepCalm", Helvetica, sans-serif;
    color: #000;
    font-size: 1.5rem;
    
    line-height: 1.2;
    
  }
  
.job-title {
    display: flex;
    
    
    
}
.title-button-for-job-type{
    font-size: 18px;
    margin-bottom: 10px;
    color: #0096FF;
    border: none;
    border: 30px;
    cursor: pointer;
    border-radius: 50px;
    padding: 10px 20px;
}
.right-aligned-button {
    display: flex;
    align-items: end;
    justify-content: end;
}
.text-allign{
    text-align: left !important;
}
.card-2{
    padding: 2px !important;
    /* text-align: justify !important; */
    
}
.working-2{
    /* margin-left: 0px !important;
    background-color: #fff; */
}
.header-text{
    font-family: "KeepCalm", Helvetica, sans-serif;
    color: #FFFFFF;
    font-size: 22px;
}
.card-text-1 {
    font-family: "circular_std", Helvetica, sans-serif;
    color: #fff;
    /* Left alignment */
    
}

/* Additional styles for responsiveness (adjust as needed) */
@media (max-width: 768px) {
    .card-text-1 {
        direction: ltr; 
    }
}

.for-career-main-text h1{
    margin-left: 30px;
    line-height: 3.5rem;
    padding: 10px;
    @media (max-width: 768px) {
        margin-left: 2px;
        padding: 4px;
        font-size: 2rem; /* Switch to a single column for smaller devices */
    }
}
.bg-color-for-new{
    background-color: #333e63;
}
.category-bg{
    background-color:  #E6E6FA;
    padding:2px 10px;
    border-radius: 10px;
    color: #0096FF;
    font-size: 16px;
}
.use-flex{
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-top: 0px !important;
}
.for-margin{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
