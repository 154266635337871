#footer .contact-request .contact-container {
	background-color: #f5f5f5;
}
/* #footer{
  padding-top: 100vh;
} */

#footer .contact-request .request-container {
	background-color: #0096ff;
	min-height: 299px;
	padding: 85px;
}

#footer .resources .resources-blog .card {
	/* border: none !important; */
}

#footer .resources .resources-blog .card a {
	margin-left: 20px;
}

#footer .resources .resources-blog .card-img-top {
	border-top-left-radius: 1 !important;
	border-top-right-radius: 1 !important;
	margin-left: 23px;
	border-radius: 50%;
	margin-top: 10px;
}

#footer .more-ways .more-ways-bottom-menu ul li {
	list-style: none;
}

#footer .footer-main {
	/* margin-top: 100px; */
	padding: 50px 0 0;
	background-color: #ecf1ff;
}

#footer .footer-main ul {
	list-style: none;
	padding-left: 0px;
}

#footer .footer-main ul li a {
	text-decoration: none;
	color: #212529;
}

.blog-card-image {
	height: 200px;
	overflow: hidden;
}

.blog-card-image img {
	height: 200px;
	width: 100%;
	object-fit: cover;
}

.resource-card-image {
	height: 80px;
	width: 80px;
	overflow: hidden;
}

.resource-card-image img {
	height: 80px;
	width: 80px;
	object-fit: cover;
}

.blog-card-body {
	height: 200px !important;
}

.contact-left {
	overflow: hidden;
	margin: auto;
}

.insights a {
	text-decoration: none;
	color: #000;
}

.insights-title {
	color: rgb(48, 48, 48);
}

.resources a {
	text-decoration: none;
	color: #000;
}

.card-title {
	color: rgb(27, 27, 27);
}

.card-text {
	color: rgb(49, 49, 49);
}

.insights {
	padding: 50px 0;
	background: rgb(235 238 246);
}

.insights-header {
	margin-bottom: 0;
}

.footer-main h5 {
	font-size: 18px;
	font-weight: 700;
}

.footer-main li {
	padding: 0 0 5px;
}

.footer-main li a {
	font-size: 0.9rem;
}

.insights-blog .card:hover {
	box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.resources-blog .card:hover {
	box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.copyright-section {
	display: flex;
	justify-content: center;
	border-top: 1px solid #b3b3b3;
	padding: 10px 0;
}

#footer .more-ways {
	background-color: rgba(30, 185, 196, 0.325);
	padding: 42px 0;
	margin-top: 20px;
}

#footer .more-ways .title {
	color: rgb(5, 5, 5);
	font-size: 1.3rem;
	padding: 20px 0 30px 0;
	border: none;
	background: transparent;
	margin: 0;
}

#footer .more-ways .more-ways-bottom-menu a {
	color: #fff;
	font-size: 1rem;
	padding: 10px 20px;
	background-color: #09a2ad;
	border-radius: 25px;
	transition: 0.3s;
}

#footer .more-ways .more-ways-bottom-menu a:hover {
	background-color: #07767e;
}

/*------------- Social media icon styles -----------------*/

.social-menu ul {
	/* position: absolute;
	top: 50%;
	left: 0%;
	padding: 0;
	margin: 0;
	transform: translate(0%, -50%); */
	display: flex;
	margin-left: 0px;
	margin-top: 20px;
}

.social-menu ul li {
	list-style: none;
	margin: 0 5px;
}

.social-menu ul li .social-icon {
	font-size: 30px;
	line-height: 30px;
	transition: 0.3s;
	color: #0096ff;
}

.social-menu ul li .social-icon:hover {
	color: #09a2ad;
}

.social-menu ul li a {
	position: relative;
	display: block;
	width: 30px;
	height: 30px;
	text-align: center;
	transition: 0.6s;
	/* background: #fff; */
}

/* .social-menu ul li a:hover {
	transform: translate(0, -10%);
} */

/* .social-menu ul li:nth-child(1) a:hover {
	background-color: rgba(0, 0, 0, 0.829);
}
.social-menu ul li:nth-child(2) a:hover {
	background-color: #e4405f;
}
.social-menu ul li:nth-child(3) a:hover {
	background-color: #0077b5;
}
.social-menu ul li:nth-child(4) a:hover {
	background-color: #000;
} */

.image-container {
	width: 170px; /* Fixed width */
	height: 170px; /* Fixed height */
	display: flex; /* Flexbox for centering */
	justify-content: center; /* Center horizontally */
	align-items: center; /* Center vertically */
	margin: 10px; /* Space between containers */
	border: 1px solid #f2f2f2; /* Optional: border for visibility */
	background-color: #fff; /* Optional: background color */
  }
  
  .image-container img {
	padding:5px;
	max-width: 100%; /* Ensure image fits inside */
	max-height: 100%; /* Ensure image fits inside */
  }