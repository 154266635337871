.btn-grp-1 {
	transform: skew(-15deg);
	border-radius: 150px 0px 0px 239px;
}

.btn-grp-2 {
	transform: skew(-15deg);
	border-radius: 0px;
}

/*** NavBootstrap navbar Start ***/
nav.navbar {
	background-color: #fff !important;
}
/*** NavBootstrap navbar End ***/

/*** Details nav Start ***/
.details-nav.sticky-top {
	background-color: rgb(255, 255, 255) !important;
	top: 83px !important;
}
/*** Details nav End ***/

#data-technology {
	margin-top: 72px;
}

#data-technology .header-text h3 {
	color: #333e63;
	/* background-color: #F8F9FA; */
	padding: 16px 0px 0px;
}

@media (max-width: 991px) {
	#data-technology .header-text h3 {
		padding: 0px !important;
	}
}

#data-technology nav ul li a {
	text-transform: uppercase;
	text-decoration: none;
	color: #000;
	font-weight: bold;
	cursor: pointer;
}

#data-technology nav ul li {
	list-style: none;
}

#data-technology nav ul li:not(:last-child) {
	margin-right: 3%;
}

#data-technology nav ul li:nth-child(2) {
	border-right: 3px solid #000;
	padding-right: 93px;
	margin-right: 0px;
}

#data-technology nav ul li:nth-child(3) {
	padding-left: 93px;
}

.overview-bottom {
	background-position: right 10% bottom;
	background-repeat: no-repeat;
}

/* #data-technology .overview-page{
  background-color: #f1f6f6;
} */

/* #data-technology .overview-page {
  margin-top: 5rem;
  transform: rotate(-5deg);
  background-color: #f1f6f6;
  width: 90%;
  height: 90%;
  left: 5%;
  position: relative;
  display: inline-block;
}

#data-technology .overview {
  display: inline-block;
  color: #fff;

  transform: rotate(5deg);
}

#common-section {
    margin: 108px 0px;
    padding: 30px 0px;
  transform: rotate(-3deg);
  background-color: #f1f6f6;
  width: 90%;
  height: 90%;
  left: 5%;
  position: relative;
  display: inline-block;
}

#common-section2 {
  margin: 108px 0px;
  padding: 30px 0px;

  transform: rotate(3deg);
  background-color: #f1f6f6;
  width: 90%;
  height: 90%;
  left: 5%;
  
  position: relative;
  display: inline-block;
}

#common-section .common-part {
  display: inline-block;
  color: #fff;

  transform: rotate(3deg);
}

#common-section2 .common-part2 {
  display: inline-block;
  color: #fff;

  transform: rotate(-3deg);
} */

#data-technology .overview .overview-top {
	padding-top: 85px;
}

#data-technology .overview .overview-bottom h5 {
	max-width: 447px;
	margin: 0px;
	/* margin: 0px auto; */
}

#data-technology .overview .overview-bottom .overview-bottom-top-section,
#data-technology .overview .overview-bottom .overview-bottom-bottom-section {
	min-height: 315px;
}

#data-technology
	.overview
	.overview-bottom
	.overview-bottom-top-section
	.list-describe
	ul {
		font-family: "circular_std", Helvetica, sans-serif;
	/* padding-left: 0px; */
}

#data-technology
	.overview
	.overview-bottom
	.overview-bottom-top-section
	.list-describe
	ul
	li,
#data-technology
	.overview
	.overview-bottom
	.overview-bottom-bottom-section
	.list-describe
	ul
	li {
	list-style: none;
}

#data-technology .solution {
	width: 100%;
	height: 391px;
}

#data-technology .solution .solution-heading {
	padding-left: 9%;
}

#data-technology .best-practices {
	background-color: #ffffff;
}

#data-technology .best-practices .best-practices-content {
	min-height: 315px;
}

#data-technology .horizontal-bar {
	width: 100%;
	height: 3px;
	background-color: #ff0000bd;
	transform: rotate(-7deg);
}

button {
	margin: 0 auto !important;
}

#data-technology .solutions-main {
	background-color: #f1f6f6;
}

#data-technology .solutions-main .solutions-main-heading {
	max-width: 447px;
	margin: 0px auto;
}

#data-technology .solutions-main .solutions-main-list .solutions-main-top,
#data-technology .solutions-main .solutions-main-list .solutions-main-middle,
#data-technology .solutions-main .solutions-main-list .solutions-main-bottom {
	min-height: 315px;
}

#data-technology
	.solutions-main
	.solutions-main-list
	.solutions-main-top
	.list-describe
	ul
	li,
#data-technology
	.solutions-main
	.solutions-main-list
	.solutions-main-middle
	.list-describe
	ul
	li,
#data-technology
	.solutions-main
	.solutions-main-list
	.solutions-main-bottom
	.list-describe
	ul
	li {
	list-style: none;
}

#data-technology .services {
	background-color: #f1f6f645;
}

#data-technology .services .services-heading {
	max-width: 447px;
	margin: 0px auto;
}

#data-technology .services .services-list .services-top,
#data-technology .services .services-list .services-middle,
#data-technology .services .services-list .services-bottom {
	min-height: 315px;
}

#data-technology .services .services-list .services-top .list-describe ul li,
#data-technology .services .services-list .services-middle .list-describe ul li,
#data-technology
	.services
	.services-list
	.services-bottom
	.list-describe
	ul
	li {
	list-style: none;
}

#data-technology .consulting {
	background-color: #fff;
}

#data-technology .consulting .consulting-heading {
	max-width: 665px;
	margin: 0px auto;
}

#data-technology .consulting .consulting-top-right .consulting-top-right-title {
	font-size: 43px;
}

#data-technology
	.consulting-bottom
	.consulting-bottom-right
	.consulting-bottom-right-left {
	background-color: #dcf8f9;
	width: 100%;
	min-height: 500px;
	text-align: center;
	font-size: 19px;
	font-weight: 500;
}

#data-technology
	.consulting-bottom
	.consulting-bottom-right
	.consulting-bottom-right-right {
	background-color: #e0eeb9;
	width: 100%;
	min-height: 500px;
	text-align: center;
	font-size: 19px;
	font-weight: 500;
}

#data-technology .training {
	background-color: #f1f6f645;
}

#data-technology .training .training-list .training-top,
#data-technology .training .training-list .training-middle,
#data-technology .training .training-list .training-bottom {
	min-height: 315px;
}

#data-technology .training .training-list .training-top .list-describe ul li,
#data-technology .training .training-list .training-middle .list-describe ul li,
#data-technology
	.training
	.training-list
	.training-bottom
	.list-describe
	ul
	li {
	list-style: none;
}

.section-picture {
	width: 100%;
}

/* -------------------- */
/* sticky sub nav */
/* -------------------- */

.sticky {
	position: -webkit-sticky;
	position: sticky;
}

.sticky.sticky-fixed.is-sticky {
	margin-top: 0;
	margin-bottom: 0;
	position: fixed;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.sticky.sticky-fixed.is-sticky:not([style*="margin-top"]) {
	margin-top: 0 !important;
}
.sticky.sticky-fixed.is-sticky:not([style*="margin-bottom"]) {
	margin-bottom: 0 !important;
}

.sticky.sticky-fixed.is-absolute {
	position: absolute;
}

.section-cta {
	max-width: 720px;
}

#business-context {
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
}

#best-practices {
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
}

.details-content {
	padding-top: 15px;
}

.consulting-bottom .card-top {
	padding: 20px;
	margin-bottom: 10px;
	background-color: aquamarine;
	border-bottom: 6px solid grey;
}

.consulting-bottom .selecting_item {
	border: none;
	width: 50%;
	padding: 10px 10px;
	background-color: white;
	border-radius: 10px;
}
.consulting-bottom .selecting_item:hover {
	outline: none;
}

/* /consulting-navbar */

.navbar-right-course .nav .nav-item {
	/* background-color: #fff; */
	/* border: 1px solid #4f4f4f; */
	/* border: 1px solid #a9a9a9; */
	border: none;
	margin-right: 10px;
    background: #dee5e7;
    padding: 0;
}

.navbar-right-course .nav .nav-item.active-right-course {
	color: black !important;
    background-color: #a0d1f3 !important;
    font-weight: 600;
    border: none;
    padding: 0;
}

.navbar-right-course .nav .nav-item .nav-link {
	color: black;
    font-size: 20px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    /* margin-right: 5px; */
    background: transparent;
    border: none;
	/* margin-right: 5px; */
}

.active {
	color: red !important;
	font-weight: 600;
}

.gray-section {
	background-color: #f4f7fb;
	color: #333e63;
}

.gray-section h1,
.gray-section h2,
.gray-section h3,
.gray-section h4,
.gray-section h5,
.gray-section p,
.gray-section span,
.gray-section b {
	color: #333e63;
}

.dark-section {
	background-color: #333e63;
	color: #ffffff;
}

.dark-section h1,
.dark-section h2,
.dark-section h3,
.dark-section h4,
.dark-section h5,
.dark-section p,
.dark-section span,
.dark-section b {
	color: #ffffff;
}

.navbar-right-course {
	margin-bottom: 40px;
}

:root {
	--width: 100%;
	--height: 200px;
	--top-color: #f4f7fb;
	--bottom-color: #fff;
}

.separator {
	position: relative;
	width: var(--width);
	height: var(--height);
}

.separator::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: var(--top-color);
	clip-path: polygon(100% 0, 0 0, 0 100%);
}

.separator::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: var(--bottom-color);
	clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.separator_rotate::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: var(--bottom-color);
	clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.separator_rotate::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: var(--top-color);
	clip-path: polygon(100% 0, 0 0, 0 100%);
}

.separator.reverse {
	transform: rotateY(180deg);
}

.vertical.separator {
	transform: rotate(180deg);
}

.vertical.separator.reverse {
	transform: rotateX(180deg);
}

.best-practices-solution {
	background-color: #ffffff;
}

.after-overview .separator::after {
	background-color: #f4f4f4 !important;
}

.after-overview .separator::before {
	background-color: #fff !important;
}

.separator_dark {
	position: relative;
	width: var(--width);
	height: var(--height);
}

.separator_dark::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: #fff !important;
	clip-path: polygon(100% 0, 0 0, 0 100%);
}

.separator_dark::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: #333e63 !important;
	clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.separator_dark {
	margin-bottom: -1px;
}

.separator_dark.reverse {
	margin-top: -1px;
}

.separator_dark.reverse {
	transform: rotateY(180deg);
}

.vertical.separator_dark {
	transform: rotate(180deg);
}

.vertical.separator_dark.reverse {
	transform: rotateX(180deg);
}

.tech-partner-image {
	width: 100%;
	margin: 0 auto;
}

.modern-technology-images {
	margin: 0;
}

/* .after-overview .separator::after {
  background-color: #f4f4f4 !important;
}

.after-overview .separator::before {
  background-color: #fff !important;
} */

@media (max-width: 991px) {
	.navbar-right-course .nav .nav-item {
		/* background-color: #fff; */
		/* border: 1px solid #4f4f4f; */
		/* background-color: #fff; */
	/* border: 1px solid #4f4f4f; */
	/* border: 1px solid #a9a9a9; */
	border: none;
	margin-right: 10px;
    background: #dee5e7;
    padding: 0;
	}
	.navbar-right-course .nav .nav-item .nav-link {
		color: black;
		font-size: 12px;
		/* padding: 0.5rem .5rem; */
		/* cursor: pointer; */
		/* margin-right: 5px; */
	}
}
