/* .blog-container{

} */
.polygon{
    padding: 0px;
}
.blog {
    margin-top: 20px;
}
.blog-1 , .blog-3{
    background-color: #F4FBFF;
}
.blog-2, .blog-4{
    background-color: #f8f9c5;
}
.blogContent{
    padding: 15px 15px;
    font-size: 15px;
    color: #000000;
    font-weight: 400;
}
.blogContent ol{
    margin-bottom: 0px;
}

.sub-service-section-details .banner{
    padding-left: 50px !important;
    padding-right: 25px
}
.sub-service-section-details .title{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    color: #000000;
    font-family: "KeepCalm", Helvetica, sans-serif;
    background-color: transparent;
    border: none;
    margin-top: 130px;
}

.text-center{
    text-align: center;
    margin: 0;
   
   
}

.text-center4{
    text-align: center;
    margin: 0;
    color:#fff;
    padding: 3px;
    font-size: 1.3rem;
   
}

.text-center-package{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    font-family: "KeepCalm", Helvetica, sans-serif;
   
}

.sub-service-section-details .title1{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    color: #000000;
    font-family: "KeepCalm", Helvetica, sans-serif;
    background-color: transparent;
    border: none;
    margin: 20px 0;
}

.sub-service-section-details .info-section{
    margin: 50px 0;
}


.sub-service-section-details .info-section .description{
    padding: 100px 50px;
    background-color: #333e63 ;
    color: #fff;
    font-size: 1.5rem;
    display: flex;
    text-align: justify;
    align-items: center;
    overflow-y: clip;
}

.sub-service-section-details .info-section .description.angle-right{
    padding-right: 100px;
}
.sub-service-section-details .info-section .description.angle-left{
    padding-left: 100px
}

.sub-service-section-details .info-section .description::before{
    content: '';
    position: absolute;
    top: 0; right: -50px;
    width: 100px;   height: 100%;
    background-color: #333e63 ;
    transform: skewX(-12deg);
    z-index: 1;
}

.sub-service-section-details .info-section .description.reverse::before{
    content: '';
    position: absolute;
    top: 0; left: -50px;
    width: 100px;   height: 100%;
    background-color: #333e63 ;
    transform: skewX(12deg);
    z-index: 1;
}

.sub-service-section-details .info-section .short-text{
    padding: 60px 60px 60px 110px;
    background-color: #ededf3;
    color: #333e63;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
}

.sub-service-section-details .info-section.second .short-text{
    padding: 60px 110px 60px 60px;
    background-color: #ededf3;
    color: #333e63;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
}

.sub-service-section-details .key-advantages-section {
    margin: 50px 0;
    background-color: #fff;
}

.sub-service-section-details .key-advantages-section .title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000000;
    background-color: transparent;
    border: none;
    font-family: "KeepCalm", Helvetica, sans-serif;
    margin: 20px 0;
}

.sub-service-section-details .key-advantages-section .sub-title {
    text-align: center;
    font-size: 1.8rem;
    color: #000000;
    background-color: transparent;
    border: none;
    margin: 20px 0;
}

.title1 {
    text-align: center;
    font-size: 1.8rem;
    color: #000000;
    background-color: transparent;
    border: none;
    margin: 20px 0;
}


.sub-service-section-details .key-advantages-section .key-item {
    padding: 15px;
}

.sub-service-section-details .key-advantages-section .key-item .key-img {
    height: 50px;
    width: auto;
    margin: 5px 0;
}

.sub-service-section-details .key-advantages-section .key-item .key-title {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    font-family: "KeepCalm", Helvetica, sans-serif;
    margin: 10px 0 5px 0;
}

.sub-service-section-details .key-advantages-section .key-item .key-desc {
    font-size: 18px;
    color: #3f3f3f;
    margin: 15px 0 5px 0;
}


.sub-service-section-details .key-benefits-section {
    margin: 50px 0;
    background-color: #fff;
}

.sub-service-section-details .key-benefits-section .title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000000;
    font-family: "KeepCalm", Helvetica, sans-serif;
    background-color: transparent;
    border: none;
    margin-top: 140px;
}

.sub-service-section-details .key-benefits-section .sub-title {
    text-align: center;
    font-size: 1.8rem;
    color: #000000;
    background-color: transparent;
    border: none;
    margin: 20px 0;
}

.sub-service-section-details .key-benefits-section .key-item-col:nth-child(even) .key-item{
    padding: 15px;
    background-color: #e5dbdb;
    border-radius: 10px;
}

.sub-service-section-details .key-benefits-section .key-item {
    padding: 15px;
    background-color: #dee5eb;
    border-radius: 10px;
}

.sub-service-section-details .key-benefits-section .key-item .key-img {
    height: 50px;
    width: auto;
    margin: 5px 0;
}

.sub-service-section-details .key-benefits-section .key-item .key-title {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    font-family: "KeepCalm", Helvetica, sans-serif;
    margin: 10px 0 5px 0;
}

.sub-service-section-details .key-benefits-section .key-item .key-desc {
    font-size: 18px;
    color: #3f3f3f;
    margin: 15px 0 5px 0;
}

.conclusion-section{
    margin: 100px 0 70px 0;
    /* padding: 50px 0; */
    position: relative;
}

.conclusion-section .text-part{
    font-size: 1.5rem;
    background-color:  rgb(225, 233, 255);
    min-height: 350px;
    padding-left: 60px;
    text-align: justify;
}
.conclusion-section .image-part{
    position: relative;
    min-height: 350px;
    background-color:  rgb(225, 233, 255);
}
.conclusion-section .large{
    font-size: 1.5rem;
    margin-bottom: 50px;
    text-align: center;
}
.conclusion-section .small{
    font-size: 1.2rem;
    text-align: center;
}

.sub-service-section-details .conclusion-image{
    position: absolute;
    top:-40%;
    left: 25%;
}

.sub-service-section-details .banner{
    background-image: url("/bg.png");
    height: 65px;
}

.large-text{
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
}

.border-right{
    border-right: 1px solid #7d7d7d !important;
}

.service-package-table .pricingTable-header.package{
    padding: 25px 20px;
    align-items: center;
    background: #d0dbff;
    transition: all 0.3s ease 0s;
    margin-bottom: 20px;
    height: 145px;
    overflow: hidden;
}

.service-package-table .pricingTable-header.package .Name{
    font-size: 2.2rem;
    margin-bottom: 20px;
    display: flex;
    font-family: "KeepCalm", Helvetica, sans-serif;
    justify-content: start;
    text-transform: capitalize;
}



.service-package-table .pricingTable-header.package .tagline{
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 0;
}

.service-package-table .package-content{
    text-align: left;
}

.service-package-table .package-footer{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left; 
    
}


.service-package-table .package-footer .title{
    text-align: left;
    font-size: 1rem;
    color: #4b4b4b;
    margin-left: 1.3rem;
    margin-bottom: 1px;
}

.service-package-table .package-footer .pricingTable-price{
    text-align: center;
    background-color: #0096ff;
    color:#fff;
    font-size: 15px;
    font-family: "KeepCalm", Helvetica, sans-serif;
}

.service-package-table .package-footer .pricingTable-price .pricetable{
    background-color: #0096ff;
    font-size: 15px;
    color: #fff;
    font-family: "KeepCalm", Helvetica, sans-serif;
}



.service-package-table.pricing-hov:hover .package-content {
    /* background-color: #0065ae !important; */
    transition: all 0.3s ease 0s;
}
.service-package-table .list-group-item{
    background-color: transparent;
    padding: 0.1rem 1.25rem !important;
    font-size: 0.8rem;
    color: #1d1d1d !important;
}
.service-package-table .list-group-item .list-item-value{
    padding: 0.1rem 0 0 0.3rem;
}
.service-package-table  .list-group-item .icon{
    color: #0096ff;
    font-size: 0.8rem;
}

.list-item-value1{
    font-size: 18px;;
}

.service-package-table.pricing-hov:hover .pricingTable-header.package{
    background: #0065ae !important;
}

.service-package-table.pricing-hov:hover .list-group-item{
    color: #fff !important;
}

.service-package-table.pricing-hov:hover .list-group-item .icon{
    color: #eeeeee;
}

.service-package-table.pricing-hov:hover .package-footer .title{
    font-family: "KeepCalm", Helvetica, sans-serif;
    color: #eeeeee;
}


@media (max-width: 1250px) {

    .sub-service-section-details .info-section .short-text{
        padding: 30px 30px 30px 70px;
        background-color: #ededf3;
        color: #333e63;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
    }
    
    .sub-service-section-details .info-section.second .short-text{
        padding: 30px 70px 30px 30px;
        background-color: #ededf3;
        color: #333e63;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
    }

	.sub-service-section-details .info-section .description{
       
        background-color: #333e63 ;
        color: #fff;
        font-size: 1rem;
        display: flex;
        text-align: justify;
        align-items: center;
        overflow-y: clip;
    }
    
  
    
    .sub-service-section-details .info-section .description::before{
        content: '';
        position: absolute;
        top: 0; right: -50px;
        width: 100px;   height: 100%;
        background-color: #333e63 ;
        transform: skewX(-12deg);
        z-index: 1;
    }
    
    .sub-service-section-details .info-section .description.reverse::before{
        content: '';
        position: absolute;
        top: 0; left: -50px;
        width: 100px;   height: 100%;
        background-color: #333e63 ;
        transform: skewX(12deg);
        z-index: 1;
    }
}

