        .title h3 {
          font-size: 30px;
          color: #0096ff;
          text-align: center;
        }
        .title h6 {
          font-size: 16px;
          color: #333333;
          font-style: italic;
          font-weight: 700;
          text-align: center;
        }
        @media screen and (max-width: 991px) {
          .title h3 {
            font-size: 22px;
          }
        }