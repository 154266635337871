.function-slider-right-text {
	text-align: justify !important;
	width: 41% !important;
}

.img-width {
	width: 429px;
	height: 275px;
}
.different{
	margin-top: 50px;
}
.featureContentList > div {
    margin: 2px 0px;
    padding: 4px 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.featureContentList .icon {
    color: #0096ff;
    font-size: 1rem;
    margin-right: 10px;
 }
 .featureContentList h6 {
    font-size: .9rem;
    font-weight: 400;
    font-family: "circular_std", Helvetica, sans-serif;
    color: #000000;
    padding-top: 5px;
}

#category-page {
	/* margin-top: 70px; */
	margin-top: 0;
}

#category-page .uniqueSolutionDetail-even {
	padding: 50px 0px;
	background-color: #fff;
}

#category-page .uniqueSolutionDetail-odd {
	padding: 50px 0px;
	background-color: #ebeef6;
}

#category-page .banner-bottom .carousel {
	display: inline-block;
	width: 33%;
}

#category-page .banner-bottom .carousel .carousel-indicators {
	position: relative !important;
	left: 711px !important;
	bottom: -403px !important;
}

#category-page .banner-bottom .carousel .carousel-indicators li {
	background-color: #10d3d9 !important;
	width: 10px !important;
	height: 11px !important;
	border-radius: 100% !important;
}

/* #category-page .banner-bottom .carousel .carousel-inner {
    overflow: visible !important;
}

#category-page .banner-bottom .carousel .carousel-inner .carousel-caption {
    top: 52px;
    left: 31%;
    text-align: left;
} */

#category-page .banner-bottom-text {
	position: relative;
	margin-left: 83px;
	top: -165px;
	width: 31%;
}

#category-page .banner-bottom-text h2 {
	font-size: 4rem;
	font-weight: 400;
}

#category-page .best-solution {
	background-color: #ebf4ff;
	padding: 70px 0;
}

#category-page .best-solution .best-solution-heading {
	/* max-width: 447px; */
	margin: 0px auto;
}

#category-page .best-solution-list .best-solution-top,
#category-page .best-solution .best-solution-list .best-solution-middle,
#category-page .best-solution .best-solution-list .best-solution-bottom {
	min-height: 315px;
}

#category-page
	.best-solution
	.best-solution-list
	.best-solution-top
	.list-describe
	ul
	li,
#category-page
	.best-solution
	.best-solution-list
	.best-solution-middle
	.list-describe
	ul
	li,
#category-page
	.best-solution
	.best-solution-list
	.best-solution-bottom
	.list-describe
	ul
	li {
	list-style: none;
}

#category-page .modern-technology .modern-technology-img1 {
	margin-left: 31%;
}
#category-page .modern-technology .modern-technology-img2 {
	margin-top: 14%;
}
#category-page .modern-technology .modern-technology-img3 {
	margin-left: 69%;
}

.image-block {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding-bottom: 30px;
}

.image-block img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.why-what-section .best-solution-heading h3 {
	font-size: 30px;
	font-weight: 700;
}

.why-what-section .title {
	font-size: 20px;
	font-weight: 500;
	color: #000;
	letter-spacing: 0;
}

.why-what-section .list-describe {
	color: rgb(51, 51, 51);
}

.active {
	color: red !important;
	font-weight: 600;
}

.nav .nav-item {
	cursor: pointer;
}

.nav .nav-item .nav-link {
	color: black;
	font-size: 22px;
	padding: 10px 30px;
}

/* .consulting-price-section .nav .nav-item{
  padding: 0;
  margin-right: 20px;
  background-color: #f4f4f4;
  border-radius: 4px;
}

.consulting-price-section .nav .nav-item:last-child{
  margin-right: 0;
} */

/* //overview */

.overview-bottom {
	background-position: right 10% bottom;
	background-repeat: no-repeat;
	/* padding-bottom: 10%; */
}

#category-page .overview {
	background-color: #f1f6f6;
	/* margin-top: 8%; */
	/* background-color: #fff; */
}

#category-page .overview .overview-top {
	padding-top: 85px;
}

#category-page .overview .overview-bottom h5 {
	max-width: 447px;
	margin: 0px;
	/* margin: 0px auto; */
}

#category-page .overview .overview-bottom .overview-bottom-top-section,
#category-page .overview .overview-bottom .overview-bottom-bottom-section {
	min-height: 315px;
}

/* #category-page
  .overview
  .overview-bottom
  .overview-bottom-top-section
  .list-describe
  ul {
   padding-left: 0px; 
} */

#category-page
	.overview
	.overview-bottom
	.overview-bottom-top-section
	.list-describe
	ul
	li,
#category-page
	.overview
	.overview-bottom
	.overview-bottom-bottom-section
	.list-describe
	ul
	li {
	list-style: none;
}

@media (max-width: 768px) {
	.why-what-section .title {
		font-size: 15px !important;
	}

	.nav .nav-item .nav-link {
		font-size: 15px !important;
	}
	.why-what-section .best-solution-heading h3 {
		font-size: 25px !important;
		font-weight: 700;
	}
	#category-page .banner-bottom-text h2 {
		font-size: 3rem !important;
		font-weight: 400;
	}

	.image-block {
		padding-bottom: 30px;
	}
}

/* //bussinessDescription */

#category-page .work {
	background-color: #fff;
	/* margin-top: 8%; */
	/* background-color: #fff; */
}

#category-page .work .work-top {
	padding-top: 85px;
}

#category-page .work .work-bottom h5 {
	max-width: 447px;
	margin: 0px;
	/* margin: 0px auto; */
}

#category-page .work .work-bottom .work-bottom-top-section,
#category-page .work .work-bottom .work-bottom-bottom-section {
	min-height: 315px;
}

.training-section {
	padding: 50px 0;
}

.techStack-detail .tech-names {
	border: 1px solid #cacaca;
	border-radius: 2px;
	padding: 3px 10px;
	text-align: center;
	margin-bottom: 5px !important;
}

.techStack-detail h5 {
	font-size: 30px;
}

.consulting-price-section-container .consulting-price-section .nav {
	margin-top: 15px;
	border-bottom: 1px solid #c0c0c0;
}

.consulting-price-section-container
	.consulting-price-section
	.nav
	.nav-item
	.nav-link {
	font-size: 18px;
	background: #f3f3f3;
	border-radius: 10px 10px 0px 0px;
}

.consulting-price-section-container
	.consulting-price-section
	.nav
	.nav-item
	.nav-link.active {
	font-weight: normal;
	font-size: 18px;
	border: 1px solid #c0c0c0;
	border-bottom: none;
	margin-bottom: -1px;
	background-color: #fff;
}

.techStack-detail img {
	width: 130px;
	align-self: flex-start;
}

@media (max-width: 540px) {
	.techStack-detail img {
		width: 65px;
		align-self: flex-start;
	}
}

.consulting-help-section {
	margin-bottom: 20px;
}

.modern-technology-images {
	margin: 0;
}

.consulting-price-section-container {
	padding: 50px 0;
}
