.clearfix::after {
	content: "A";
	display: block;
	clear: both;
	color: white;
}

.modal-header {
	padding: 0rem 1rem !important;
}

.modal-content {
	/* padding: 1%; */
	padding: 0 !important;
	/* overflow: hidden; */
	border: none;
}

.modal-header {
	padding: 0 !important;
}

.modal-header-row {
	padding: 11px 20px !important;
}

.consulting-form-progress label.btn {
	border-radius: 0 !important;
	border-right: 1px solid #000;
	border-color: #fcfcfc;
	font-size: 13px;
	line-height: 25px;
}

.modal-title {
	color: #2978db;
}

.consulting-form-progress label svg {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 15px;
	font-size: 15px;
}

.consulting-form-progress label.btn {
	background: #4d7180;
}

.consulting-form-progress label.btn.btn-info {
	background: #4d7180;
}

.completed {
	background: #05a370 !important;
}

.consulting-form-progress label.btn.completed {
	background: #05a370;
}

.consulting-form-progress label.btn.active {
	background: #11428b !important;
}

.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto !important;
}

.consulting-form-progress label.btn:last-child {
	border-right: none;
}
.box h2 {
	margin: 0 0 40px;
	padding: 0;
	color: #0096ff;
	text-transform: uppercase;
	margin-right: 45%;
	margin-bottom: 0;
}

.box input {
	padding: 10px 0 10px 10px;
	margin-bottom: 30px;
}

.box textarea {
	height: 43px !important;
	padding: 10px 0 10px 10px !important;
	margin-bottom: 40px;
	box-shadow: none !important;
	border-radius: 0;
	margin-top: 20px;
	background-color: #f4f4f4;
}

.box input,
.box textarea {
	width: 100%;
	box-sizing: border-box;
	box-shadow: none;
	outline: none;
	border: none;
	border-bottom: 1px solid #999 !important;
	border-radius: 0;
}

.box input[type="submit"] {
	border-bottom: none;
	cursor: pointer;
	background: #0096ff;
	color: #fff;
	margin-bottom: 0;
	text-transform: uppercase;
	/* margin-top: -19px; */
	/* margin-top: 3px; */
}

.box input[type="text"],
.box textarea[type="text"],
.box input[type="email"],
.box input[type="submit"] {
	font-size: 15px;
	margin-top: 20px;
    padding-top: 10px !important;
    padding-left: 10px;
	background-color: #f4f4f4;
}

form .box div {
	position: relative;
}

form .box div label {
	position: absolute;
	top: 10px;
	left: 0;
	color: #999;
	transition: 0.5s;
	pointer-events: none;
}

.form-group.box {
	position: relative;
}

.form-group.box label {
	position: absolute;
	top: 10px;
	left: 0;
	color: #999;
	transition: 0.5s;
	pointer-events: none;
}

.box input:focus ~ label {
	top: -7px;
	left: 0;
	color: #0096ff;
	font-size: 15px;
	font-weight: bold;
}
.box input:not(:focus):valid ~ label {
	top: -7px;
	left: 0;
	color: #0096ff;
	font-size: 15px;
	font-weight: 400;
}

.box input:focus,
.box textarea:focus,
._3vt7_Mh4hRCFbp__dFqBCI input:focus {
	border-bottom: 1px solid #0096ff !important;
	border-radius: 0;
	background-color: #f4f4f4;
}

.box textarea:focus ~ label {
	top: -25px;
	left: 0;
	color: #0096ff;
	font-size: 15px;
	font-weight: bold;
}
.box textarea:not(:focus):valid ~ label {
	top: -25px;
	left: 0;
	color: #0096ff;
	font-size: 15px;
	font-weight: 400;
}

.dropdown-levels {
	color: #0096ff;
}

.select-one {
	padding: 8px 0 12px 0 !important;
	background-color: #fff;
	border: none;
	border-bottom: 2px solid #999;
	font-size: 1rem;
	color: #4f4f4f;
	border-radius: 0;
	margin-top: 0;
}

.select-one:focus {
	outline: none;
}

.select-option {
	padding: 10px 0px;
	font-size: 1rem;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.7);
	/* z-index: 100; */
}

.overlay__wrapper {
	width: 100%;
	height: 100%;
	position: relative;
}

.overlay__spinner {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	display: flex;
}

/* ._2iA8p44d0WZ-WqRBGcAuEV {
  border: none !important;
  padding: 0px !important;
  padding-top: 5px !important;
}

._3vt7_Mh4hRCFbp__dFqBCI input {
  border-bottom: 1px solid #999 !important;
  font-weight: 600 !important;
}

._3vt7_Mh4hRCFbp__dFqBCI input {
  border-bottom: 1px solid #999 !important;
  font-weight: 600 !important;
}

._3vt7_Mh4hRCFbp__dFqBCI input.searchBox {
  margin-bottom: 0 !important;
  padding-top: 0px !important;
} */

/* For Checkbox */
/* ._3fD5brWjGxPOXWAlZj16mH {
  width: 7% !important;
  margin-bottom: 0 !important;
} */

/* react-select style start */

/* .css-1rhbuit-multiValue {
  margin-bottom: 30px !important;
}

.css-g1d714-ValueContainer {
  padding: 0px !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding-bottom: 25px !important;
}

.css-1okebmr-indicatorSeparator {
  height: 35px !important;
}

.css-1euusua-control {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid #0096ff !important;
} */

/* react-select style end */

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
	color: #fff !important;
	background-color: #0096ff !important;
	border-color: #0096ff !important;
}

.progress {
	border-radius: 0rem !important;
	border-bottom: 1px solid #a9a9a96b;
	height: 0.3rem;
	margin: 0 -15px;
}
.progress-bar {
	color: #007bff;
}

.col-form-label {
	top: -33px !important;
}

.box .third_page {
	margin: 3rem 0;
}

.box .third_page textarea {
	padding-top: 8px;
	font-size: 18px;
}

.cta-form-btn {
	padding: 12px 0;
	border-radius: 0;
	font-size: 20px;
}

.basic-multi-select {
	text-align: left !important;
}
.bla {
	min-height: auto;
}

.box .input-group input {
	width: auto;
	padding: 10px;
	margin-bottom: 0;
	flex: 1;
	border-bottom: none;
	border: 1px solid #b2b2b2;
}

.upload-btn {
	width: 125px;
}

.loading-msg {
	padding: 5px 15px;
	font-size: 15px;
	font-weight: bold;
	background: #d7d7d7;
	border-radius: 15px;
	margin-left: 10px;
	color: #000;
}

/*============================== Responsive css ================================*/

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {
	.modal-title {
		font-size: 1rem;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.modal-title {
		font-size: 1.5rem;
	}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
	.modal-title {
		font-size: 2rem;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
