.singleSliderWrapper {
  height: 550px;
  
}
.sliderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.heading {
  color: #fff;
  font-size: 2rem;
  line-height: 3rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 992px) {
  .singleSliderWrapper {
    height: 300px;
    margin-top: 50px;
  }
  .heading {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .singleSliderWrapper {
    height: 250px;
    margin-top: 50px;
  }
  .heading {
    font-size: 1rem;
    line-height: 2rem;
  }
}
