/*** progress wizard custom css ***/
.submenuHeaderItemTab a {
  color: #406753;
  text-decoration: none;
  border-bottom: 1px dotted #999;
}
.submenuHeaderItemTab a:hover {
  color: black;
  border-color: black;
}
.submenuHeaderItemTab hr {
  opacity: 0.3;
}
.submenuHeaderItemTab h1 {
  font-size: 50px;
}
.submenuHeaderItemTab h3 {
  border-left: 12px solid #f1f1f1;
  text-indent: 1em;
  color: #999;
  margin-top: 2em;
  font-weight: 100;
}
.submenuHeaderItemTab ul {
  margin: 0;
  padding: 0.4em;
  list-style-type: square;
}
.submenuHeaderItemTab li {
  padding-left: 0.5em;
  line-height: 2.4em;
}
.submenuHeaderItemTab code {
  font-family: Monaco, Consolas, "Lucida Console", monospace;
  background-color: #f1f1f1;
  font-size: 70%;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.submenuHeaderItemTab .subdued {
  font-size: 80%;
  opacity: 0.6;
}

/*** own edited start ***/

.progress-indicator > li p {
  font-size: 19px;
  font-weight: bold;
}
.progress-indicator > li .bubble {
  cursor: pointer;
}
.progress-indicator > li.active,
.progress-indicator > li.active .bubble {
  color: #17cdc6 !important;
}
.progress-indicator > li.active .bubble,
.progress-indicator > li.active .bubble:after,
.progress-indicator > li.active .bubble:before {
  background-color: #17cdc6 !important;
}

/*** own edited end ***/

/* A totally custom override */

.progress-indicator.custom-complex {
  background-color: #f1f1f1;
  padding: 10px 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.progress-indicator.custom-complex > li .bubble {
  height: 12px;
  width: 99%;
  border-radius: 2px;
  box-shadow: inset -5px 0 12px rgba(0, 0, 0, 0.2);
}
.progress-indicator.custom-complex > li .bubble:before,
.progress-indicator.custom-complex > li .bubble:after {
  display: none;
}

/* Demo for vertical bars */

.progress-indicator.stepped.stacked {
  width: 48%;
  display: inline-block;
}
.progress-indicator.stepped.stacked > li {
  height: 150px;
}
.progress-indicator.stepped.stacked > li .bubble {
  padding: 0.1em;
}

.progress-indicator.stepped.stacked > li:first-of-type .bubble {
  padding: 0.5em;
}
.progress-indicator.stepped.stacked > li:last-of-type .bubble {
  padding: 0em;
}

/* Nocenter */

.progress-indicator.nocenter.stacked > li {
  min-height: 100px;
}
.progress-indicator.nocenter.stacked > li span {
  display: block;
}

/* Demo for Timeline vertical bars */

#timeline-speaker-example {
  background-color: #2b4a6d;
  color: white;
  padding: 1em 2em;
  text-align: center;
  border-radius: 10px;
}
#timeline-speaker-example .progress-indicator {
  width: 100%;
}
#timeline-speaker-example .bubble {
  padding: 0;
}
#timeline-speaker-example .progress-indicator > li {
  color: white;
}
#timeline-speaker-example .time {
  position: relative;
  left: -80px;
  top: 30px;
  font-size: 130%;
  text-align: right;
  opacity: 0.6;
  font-weight: 100;
}
#timeline-speaker-example .current-time .time {
  font-size: 170%;
  opacity: 1;
}
#timeline-speaker-example .stacked-text {
  top: -37px;
  left: -50px;
}
#timeline-speaker-example .subdued {
  font-size: 10px;
  display: block;
}
#timeline-speaker-example > li:hover {
  color: #ff3d54;
}
#timeline-speaker-example > li:hover .bubble,
#timeline-speaker-example > li:hover .bubble:before,
#timeline-speaker-example > li:hover .bubble:after {
  background-color: #ff3d54;
}
#timeline-speaker-example .current-time .sub-info {
  font-size: 60%;
  line-height: 0.2em;
  text-transform: capitalize;
  color: #6988be;
}
@media handheld, screen and (max-width: 400px) {
  .container {
    margin: 0;
    width: 100%;
  }
  .progress-indicator.stacked {
    display: block;
    width: 100%;
  }
  .progress-indicator.stacked > li {
    height: 80px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .progress-indicator > li p {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2rem;
  }
}

@media (max-width: 768px) {
  .progress-indicator > li p {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2rem;
  }
}
