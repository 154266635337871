.demo {
	padding: 20px 0;
}
.heading-title {
	margin-bottom: 100px;
}

.m-t-30 {
	margin-top: 30px;
}

.pricingTable {
	text-align: center;
	border: 1px solid #dbdbdb;
	position: relative;
	overflow: hidden;
	height: 100%;
}

.daily .pricingTable-header {
	height: 230px;
}

.hourly .pricingTable-header {
	height: 280px;
}

.monthly .pricingTable-header {
	height: 200px;
}

.pricingTable-header {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pricingTable .pricingTable-header h3 {
	font-size: 18px;
	text-transform: uppercase;
}

.pricingTable .pricingTable-header .selection {
	width: 60%;
	margin-top: auto;
	margin-bottom: 25px;
}

.pricingTable .pricingTable-header .title {
	font-size: 18px;
	text-transform: uppercase;
	border: none;
	margin: 0;
	padding: 35px 0;
	padding-top: 3rem;
	color: #000;
}

.pricingTable:hover .pricingTable-header .title {
	color: #fff;
}

.pricingTable .price-value {
	background: #fafafa;
	color: #666;
	font-weight: 900;
	padding: 15px 0;
	transition: all 0.3s ease 0s;
}

.pricingTable .price-value span {
	display: inline-block;
	font-size: 40px;
	font-weight: normal;
	line-height: normal;
}

.pricingTable .price-value small {
	font-size: 20px;
	position: relative;
	top: -12px;
	left: 0;
}

.pricingTable .price-value sub {
	font-size: 18px;
	position: relative;
	bottom: -1px;
	left: 0;
}

.pricingTable .price-value .subtitle {
	color: #82919f;
	display: block;
	font-size: 16px;
	font-weight: 100;
	font-style: italic;
}

.pricingTable .price-value-daily {
	background: #fafafa;
	color: #666;
	font-weight: 900;
	padding: 15px 10px;
	transition: all 0.3s ease 0s;
}

.pricingTable .price-value-daily span {
	color: #666;
	display: inline-block;
	font-size: 40px;
	font-weight: normal;
	line-height: normal;
}

.pricingTable .price-value-daily small {
	font-size: 20px;
	position: relative;
	top: -12px;
	left: 0;
}

.pricingTable .price-value-daily .subtitle {
	color: #82919f;
	display: block;
	font-size: 16px;
	font-weight: 100;
	font-style: italic;
}

.pricingTable .best-offer {
	background-color: #3498db;
	color: #fff;
	padding: 6px 50px;
	font-size: 10px;
	line-height: 12px;
	text-transform: uppercase;
	position: absolute;
	top: 20px;
	right: -55px;
	transform: rotate(45deg);
}

.pricingTable .pricingContent ul {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

.pricingTable .pricingContent ul li {
	border-bottom: 1px solid #ededed;
	color: #9999a5;
	padding: 10px 0;
}

.pricingTable .pricingContent ul li:first-child {
	border-top: 1px solid #ededed;
}

.pricingTable .pricingTable-sign-up {
	padding: 25px 0;
	margin-top: auto;
}

.pricingTable .btn-block {
	background: #666;
	border-radius: 0px;
	border: 0px none;
	color: #fff;
	width: 50%;
	padding: 10px 5px;
	margin: 0 auto;
	text-transform: capitalize;
	transition: all 0.3s ease 0s;
}

.pricingTable .btn-block:after {
	content: "\f090";
	font-family: "FontAwesome";
	padding-left: 10px;
	font-size: 15px;
}

.pricingTable .btn-block:hover {
	background: #282828;
	color: #fff;
}

.selecting_item {
	/* border: none; */
	width: 50%;
	padding: 10px 10px;
	background-color: white;
	border-radius: 0px;
}
.selecting_item:hover {
	outline: none;
}

.price-hov {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	transition: box-shadow 0.25s;
}

.pricing-hov {
	transition: all 0.3s ease 0s;
}

.pricing-hov:hover {
	background-color: #1684d3 !important;
	cursor: pointer;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
}

.pricing-hov:hover .price-value {
	background-color: #0065ae !important;
}

.pricing-hov:hover .price-value-daily {
	background-color: #0065ae !important;
}

.pricing-hov:hover .fa-usd {
	color: #fff;
}
.pricing-hov:hover .btn-block {
	background: #fff;
	color: #0065ae;
}

.pricing-hov:hover h1,
.pricing-hov:hover h2,
.pricing-hov:hover h3,
.pricing-hov:hover h4,
.pricing-hov:hover h5,
.pricing-hov:hover p,
.pricing-hov:hover b,
.pricing-hov:hover span {
	color: #fff;
}

@media screen and (max-width: 990px) {
	.pricingTable {
		/* margin-bottom: 20px; */
	}
}
