.blog .blog-top .topImg img {
  width: 21%;
}

.blog .blog-top .navbar-light .navbar-nav .nav-link {
  color: #0096ff !important;
  padding: 10px 31px;
  font-weight: bold;
}

.navbar-header .nav-link {
  color: #0096ff !important;
  padding: 10px 31px;
  font-weight: bold;
}

.blog .blog-main .blog-title {
  position: relative;
}

/* .blog .blog-main .blog-title .stripe-line {
    background-image: url('../../Images/stripe.png');
    height: 5px;
    width: 100%;
} */

.slick-prev:before,
.slick-next:before {
  color: gray;
}

.blog-main p {
  color: black;
}

/* .blog .blog-main .blog-item .card .card-img-top{
    height: 50px;
} */

/*** navbar and footer none ***/
/* .nav-section {
    display: none;
}

.contact-request {
    display: none;
}

.insights {
    display: none;
}

.resources {
    display: none;
}

.more-ways {
    display: none;
} */

/* modal */

.modal-newsletter {
  color: #999;
  font-size: 15px;
  /* min-width: 600px; */
}
.modal-newsletter .modal-content {
  /* padding: 40px; */
  border-radius: 0;
  border: none;
}
.modal-newsletter .modal-header {
  border-bottom: none;
  position: relative;
  text-align: center;
  border-radius: 5px 5px 0 0;
}
.modal-newsletter h4 {
  color: #1a74f8;
  text-align: center;
  font-size: 20px;
  /* margin: 0 0 25px; */
  font-weight: bold;
  /* text-transform: capitalize; */
}

.modal-newsletter h3 {
  color: #1a74f8;
  text-align: center;
  font-size: 35px;
  margin: 0 0 25px;
  font-weight: bold;
  /* text-transform: capitalize; */
}

.modal-newsletter .modal-body {
  padding: 0rem;
}

.modal-newsletter p {
  color: black;
  font-size: 0.7rem;
  font-family: "circular_std", Helvetica, sans-serif;
}

.modal-newsletter .close {
  background: #c0c3c8;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  text-shadow: none;
  /* opacity: 0.5;
  width: 30px;
  height: 30px; */
  opacity: 1;
  width: 0;
  height: 0;
  z-index: 1;
  border-radius: 20px;
  font-size: 19px;
  text-align: center;
  padding: 0;
}

.modal-newsletter .left-image {
  /* background-position: center;
    background-size: cover; */
}
.modal-newsletter .close span {
  position: relative;
  top: -10px;
  left: -15px;
  /* padding: 5px 11px; */
  padding: 6px 12px 8px;
}
.modal-newsletter .close:hover {
  opacity: 0.8;
}
.modal-newsletter .icon-box {
  color: #7265ea;
  display: inline-block;
  z-index: 9;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
.modal-newsletter .icon-box i {
  font-size: 110px;
}
.modal-newsletter .form-control,
.modal-newsletter .btn {
  min-height: 46px;
  border-radius: 3px;
}
.modal-newsletter .form-control {
  box-shadow: none;
  border-color: #dbdbdb;
  border-radius: 20px;
}
.modal-newsletter .form-control:focus {
  border-color: #7265ea;
  box-shadow: 0 0 8px rgba(114, 101, 234, 0.5);
}
.modal-newsletter .btn {
  color: #fff;
  border-radius: 4px;
  background: #7265ea;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  padding: 6px 20px;
  min-width: 150px;
  border: none;
  border-radius: 20px;
  margin-bottom: 10px;
}
.modal-newsletter .btn:hover,
.modal-newsletter .btn:focus {
  background: #4e3de4;
  outline: none;
}
.modal-newsletter .input-group {
  margin: 30px 0 15px;
}
.hint-text {
  margin: 100px auto;
  text-align: center;
}

.card {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.25s;
}
.card:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-card {
  width: 100%;
  height: 200px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  overflow: hidden;
}
.img-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 0.25s ease;
}
.card-content {
  padding: 15px;
  font-family: "circular_std", Helvetica, sans-serif;
  text-align: left;
}
.card-title {
  margin-top: 0px;
  font-weight: 700;
  font-size: 1.65em;
}
.card-details{
  font-family: "circular_std", Helvetica, sans-serif;
}
.card-title a {
  color: #000;
  text-decoration: none !important;
}
.card-read-more {
  border-top: 1px solid #d4d4d4;
}
.card-read-more a {
  text-decoration: none !important;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.subscription-div {
  background-color: #333e63;
  padding: 20px 0px;
}

/* .subscription-div p {
  text-align: center;
  color: #91b7b5;
  padding-bottom: 20px;
  width: 100%;
} */

/* .subscription-div input[type="text"] {
  width: 30%;
  padding: 10px 10px;
  margin: 10px 0px;
  font-size: 1.2rem;
}

.subscription-div button {
  width: 30%;
  padding: 10px 0px;
  background-color: #91b7b5;
  margin: 10px 0px;
  border: none;
  border-radius: 3px;
} */

.blog-category-main {
  padding: 100px 0;
}

.blog-category-main .blog-category-title {
  font-size: 30px;
  padding: 5px 0;
  border-bottom: 1px solid rgb(128, 177, 252);
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
