.job-description {
  background-color: #fff;

}

.job-description-container {
  display: grid;
  grid-template-columns: 8fr 4fr;

}

.go-back {
  margin-top: 15px;
  margin-bottom: 10px;
}

.icon-color {
  color: #0096FF;


}

.container-icon p {
  display: flex;
  align-items: flex-start;
}

.container-icon p span.icon-color {
  margin-right: 5px;
}

.container-icon p::first-line {
  text-indent: 0;
}

.apply-now-btn-dsgn {
  padding: 20px;
  

}

.part-2-job-post {
  /* position: fixed;
    top: 200px; 
    left: 1100px; */
  height: 400px;
  width: 300px;
  max-height: 100%;
  padding: 30px;
  background-color: #FFFFFF;
  margin-left: 200px;
  margin-top: 200px;
}

.text-center {
  text-align: center;
  
  margin: 0; /* Add this line to remove any default margin */
}

/* .apply-button {
    position: fixed;
    top: 200px; 
    right: 20px; 
    padding: 10px 20px;
    
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
} */
.container-icon p{
  font-family: "circular_std", Helvetica, sans-serif;
    color: #595959;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 0;
    margin-bottom: 1rem;
}
.title-center{
  text-align: center;
  margin-top: 10px;
  padding: 10px;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.for-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-gap: 20px;
  margin-top: 5px;
}

.left-item {
  grid-column: 1 / span 1; /* Place this item in the first column */
}

.right-corner-item {
  
  grid-column: 2 / span 1; /* Place this item in the second column */
  text-align: right; /* Align text to the right for the right corner */
}

/* Media query for small devices (adjust the max-width as needed) */
@media (max-width: 768px) {
  .left-item {
    grid-column: 1 / span 2; /* Make left-item span both columns on small devices */
    text-align: center; /* Center text for left-item on small devices */
  }

  .right-corner-item {
    grid-column: 1 / span 2; /* Make right-corner-item span both columns on small devices */
    text-align: center; /* Center text for right-corner-item on small devices */
  }
  .job-description h3 {
    font-size: 1.25rem; /* Adjust the font size for smaller devices */
}
}

.deadline{
  color: red;
  font-size: 20px;
  font-weight: 700;
}


.for-pp {
  font-size: 18px; 
}
.do-flex{
  display: flex;
  align-items:baseline;
  justify-content: space-between;
}
.for-font-size{
  font-size: 14px !important;
    color: #000;
    font-family: "circular_std", Helvetica, sans-serif;
    color: #000;
    line-height: 1;
    
}
.clr-black{
  font-size:22px;
  color: black !important;
}

.job-black{
  font-size:18px;
  color: black !important;
}
.for-h4{
  font-size: 16 !important;
  
}
.for-p-others-job p{
  font-size: 18px !important;
}
.job-info-item{
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
@media (max-width: 768px) {
  .apply-now-btn-dsgn {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .btn-primary {
    font-size: 16px; /* Adjust the font size for the button */
  }
}
