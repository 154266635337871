body::before {
  content: "";
  position: fixed;
}

.sticky {
  position: -webkit-sticky;
  position: fixed;
  /* top: 10; */
}

.sticky.sticky-fixed.is-sticky {
  margin-top: 0;
  margin-bottom: 0;
  position: fixed;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.sticky.sticky-fixed.is-sticky:not([style*="margin-top"]) {
  margin-top: 0 !important;
}
.sticky.sticky-fixed.is-sticky:not([style*="margin-bottom"]) {
  margin-bottom: 0 !important;
}

.sticky.sticky-fixed.is-absolute {
  position: absolute;
}

#top-b {
  margin-top: 32px;
}

.details-nav {
  position: fixed;
  z-index: 1000;
  background-color: #f5f5f5;
  top: 70px;
}

ul.details-nav-items {
  padding: 10px 0 5px;
  cursor: "pointer";
}

@media only screen and (max-width: 600px) {
  ul.details-nav-items {
    padding: 0 0 5px;
  }
}
