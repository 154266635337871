@media (max-width: 575.98px) {
    .carousel-caption {
        bottom: 15px!important;
    }
    
    .carousel-caption h3 {
        font-size: 20px;
        color: #0096FF;
    }
    
    .carousel-caption p {
        font-size: 12px;
    }

    .carousel-indicators {
        bottom: -21px;
    }

    .carousel-caption .caption-title{
        font-size: 2rem;
    }

    .menu-hero-slider .carousel-item {
        min-height: 250px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) { 
    .menu-hero-slider .carousel-item {
        min-height: 350px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .carousel-caption {
        bottom: 21px!important;
    }
    
    .carousel-caption h3 {
        font-size: 45px;
        color: #0096FF;
    }
    
    .carousel-caption p {
        font-size: 23px;
    }

    .menu-hero-slider .carousel-item {
        min-height: 350px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) { }

@media (min-width: 1200px) { }
