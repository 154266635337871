/*** navbar and footer none ***/
/* .nav-section {
    display: none;
}

.contact-request {
    display: none;
}

.insights {
    display: none;
}

.resources {
    display: none;
}

.more-ways {
    display: none;
} */

.blog-side-image {
  width: 50px;
  height: 50px;
}

.blog-link {
  color: black;
}

.blog-link:hover {
  text-decoration: none;
  color: black;
}

.class_details {
  margin-top: 100px;
}
