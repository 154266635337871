@media (min-width: 200px) and (max-width: 320px) {
	.navbar-nav {
		padding-left: 5px !important;
		height: 80vh;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 17rem;
    height: 90vh;
    overflow: auto;
  } */
}

@media (min-width: 321px) and (max-width: 399.98px) {
	.navbar-nav {
		padding-left: 5px !important;
		height: 82vh;
		overflow-y: auto;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 21rem;
    height: 81vh;
    overflow: auto;
  } */
}

@media (min-width: 400px) and (max-width: 499.98px) {
	.navbar-nav {
		margin-top: 20px;
		padding-left: 15px !important;
		padding-right: 15px !important;
		overflow-y: auto;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 21rem;
    height: 100vh;
    overflow: auto;
  } */
}

@media (min-width: 500px) and (max-width: 575.98px) {
	.navbar-nav {
		padding-left: 5px !important;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 32rem;
    height: 82vh;
    overflow: auto;
  } */
}

@media (min-width: 576px) and (max-width: 648.98px) {
	.navbar-nav {
		margin-top: 20px;
		padding-left: 15px !important;
		padding-right: 15px !important;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 38rem;
    height: 82vh;
    overflow: auto;
  } */
}

@media (min-width: 649px) and (max-width: 689.98px) {
	.navbar-nav {
		padding-left: 5px !important;
		height: 82vh;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 40rem;
    height: 82vh;
    overflow: auto;
  } */
}

@media (min-width: 690px) and (max-width: 767.98px) {
	.navbar-nav {
		padding-left: 5px !important;
		height: 84vh;
		overflow-y: auto;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 100%;
    height: auto;
    overflow: auto;
  } */
}

@media (min-width: 767.98px) and (max-width: 991px) {
	.navbar-nav {
		padding-left: 5px !important;
	}

	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
	}

	/* .navbar .nav-item .drop-mobile {
    width: 47rem;
    height: 82vh;
    overflow: auto;
  } */
}

@media (max-width: 991.98px) {
	.navbar-light .navbar-toggler {
		/* margin-left: 0% !important; */
		margin: 0px !important;
	}

	.actives label {
		font-weight: bold;
		color: #0096ff;
	}

	.navbar .nav-item .dropdown-menu-two {
		display: block;
		opacity: 0;
		visibility: hidden;
		transition: none;
		margin-top: 0;
		width: 50rem;
	}

	.navbar .subNav-icon:hover .dropdown-menu-two {
		transition: none;
		opacity: 1;
		visibility: visible;
		top: 100%;
		transform: rotateX(0deg);
	}
}

@media all and (min-width: 992px) {
	.dropdown-large {
		min-width: 500px;
	}

	.navbar .nav-item .dropdown-menu-two {
		display: block;
		opacity: 0;
		visibility: hidden;
		transition: none;
		margin-top: 0;
		width: 50rem;
	}

	.title {
		color: #06d5f7;
		/* letter-spacing: 3px; */
		padding-bottom: 7px;
	}

	.nav-item-border {
		border-left: 1px solid #17cdc6;
		border-right: 1px solid #17cdc6;
		/* margin-left: 31px; */
		/* margin-right: 31px; */
	}

	.navbar-toggler:focus,
	.navbar-toggler:active,
	.navbar-toggler-icon:focus {
		outline: none;
		box-shadow: none;
	}

	.navbar-light .navbar-nav .nav-link {
		color: #0096ff !important;
		padding-right: 1.5rem;
	}

	.list-unstyled li a {
		color: #0096ff;
	}

	.dropdown-menu-two ul li {
		/* padding: 5px 0px; */
		padding: 0;
		min-width: 200px;
	}

	.navbar .nav-item:hover .nav-link {
		color: #fff;
	}

	.navbar .dropdown-menu-two.fade-down {
		top: 80%;
		transform: rotateX(-75deg);
		transform-origin: 0% 0%;
	}

	.navbar .dropdown-menu-two.fade-up {
		top: 180%;
	}

	.navbar .nav-item:hover .dropdown-menu-two {
		transition: none;
		opacity: 1;
		visibility: visible;
		top: 100%;
		transform: rotateX(0deg);
	}

	.dropdown-toggle::after {
		vertical-align: 0rem !important;
	}

	.dropdown-large {
		padding: 20px !important;
	}

	.dropdown-item {
		padding: 0.5rem 5.5rem !important;
	}

	.navbar-expand-lg .navbar-nav .nav-link {
		padding: 1.5rem 0.5rem !important;
	}
}

/* @media (min-width: 768px) and (max-width: 991.98px) { 
	button.navbar-toggler {
		margin: 0px !important;
	}
} */
