/* JobApplyForm.css */

.job-apply-form {
    background-color: #f4fbff;
    padding: 20px;
}

.title-center {
    text-align: center;
    font-size: 24px;
    color: #0096FF;
    margin-bottom: 20px;
}

.category-name {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
}

.personal-info-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.div-1,
.div-2 {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.personal-info-title {
    font-size: 20px;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 20px;
}

/* label {
    font-weight: bold;
} */

.input-width {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.custom-file-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}

.apply-button-2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn-send-application {
    background-color: #0096FF;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.send-icon {
    margin-right: 5px;
}
