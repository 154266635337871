.solution-partners {
  margin-top: 100px;
}

.partner-title {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 5px 0;
  color: #000;
}

.partner-desc {
}
