.dashboardSliderSection {
    margin: 70px 0px;
}
.dashboardSliderSection h3 {
    color: #0096ff;
    font-size: 42px;
    padding-bottom: 40px;
}
.dashboardPaginationWrapper {
    margin-top: 50px;
}

        .dashboardSliderCardContainer {
          height: 500px;
          width: 100%;
          position: relative;
          overflow: hidden;
          padding: 20px;
        }
        .dashboardSliderCardContainer:hover .dashboardSliderCardContainer__content{
          margin-bottom: 0px;
        }
        .dashboardSliderCardContainer img{
          height: 100%;
          width: 100%;
        }
        .dashboardSliderCardContainer__content {
          display: block;
          position: absolute;
          bottom: 0;
          padding: 15px 30px;
          background: #0096ff94;
          width: 55%;
          /* margin-bottom: -40px; */
          transition: margin-bottom .3s ease-in-out;
          cursor: pointer;
          text-decoration: none;
        }
        .dashboardSliderCardContainer__content:hover {
          text-decoration: none;
        }
        .dashboardSliderCardContainer__content h3 {
          font-size: 20px;
          font-weight: 700;
          color: #ffffff;
        }
        .dashboardSliderCardContainer__content h3:hover {
          color: #012954;
        }
        .dashboardSliderCardContainer__content__categoriesList {
          list-style: none;
          padding: 0px;
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
        }
        .dashboardSliderCardContainer__content__categoriesList li {
          padding-left: 5px;
          margin-left: 5px;
          border-left: 1px solid white;
        }
        .dashboardSliderCardContainer__content__categoriesList li:first-child {
          border-left: none;
          margin-left: 0px;
          padding-left: 0px;
        }
        .dashboardSliderCardContainer__content__categoriesList li a {
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
        .dashboardSliderCardContainer__content__linkBox{
            padding-top: 20px;
        }
        .dashboardSliderCardContainer__content__linkBox a{
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
            text-decoration: none;
        }
        .dashboardSliderCardContainer__content__linkBox a:hover{
            color: #012954;
        }

        