.uniqueSolutionDetail {
  padding: 50px 0px;
}

.uniqueSolutionDetail img {
  width: 100% !important;
  height: auto !important;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .uniqueSolutionDetail .row {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
